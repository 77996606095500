import React, { useContext } from 'react'
import overlayContext from '../../Context/overlayContext';
import { decryptedUserId, setCookie } from '../../utils';
import { doRequest } from '../../utils/doRequest';
import CloseSVG from '../Ui/CloseSVG';

export default function ConsetOverlay({showOverlayOF}) {

    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);

    function closeOverlay() {
        setShowoverlay('');
    }

    function openLivestream() {
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('livestream');
        }, 100);
    }

    function openVideo() {
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('csVideo');
        }, 100);
    }

    function openPodcast() {
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('csPodcast');
        }, 100);
    }

    function openWhitepaper() {
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('csWhitepaper');
        }, 100);
    }

    function openArticle() {
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('csArticle');
        }, 100);
    }

    function closeOverlayAndRejectConsent() {
        doRequest( 'Server', 'server.php', 'GET', {
                type: "consent",
                setConsent : 1,
                uid: decryptedUserId(),
                consent: 2
            },
            false,
            (err,data) => {
                setCookie('consent', data.responseArr.third_party_consent, 365);
                closeOverlay();
            }
        );
    }
    
    function closeOverlayAndAcceptConsent(showOverlayOF) {
        doRequest('Server', 'server.php', 'GET', {
                type: "consent",
                localId: process.env.REACT_APP_LOCAL_ID,
                setConsent : true,
                uid: decryptedUserId(),
                consent: 1
            },
            false,
            (err,data) => {
                setCookie('consent', data.responseArr.third_party_consent, 365);
    
                if(showOverlayOF == 'whitepaper') {
                    openWhitepaper()
                }
                if(showOverlayOF == 'podcast' ) {
                    openPodcast()
                }

                if(showOverlayOF == 'video') {
                    openVideo()
                }
    
                if(showOverlayOF == 'article') {
                    openArticle()
                }

                if(showOverlayOF == 'livestream') {
                    openLivestream();
                } else {
                    closeOverlay();
                }
            }
        );
    }  

    return (
    <div className="overlay scroll-overlay active" id="cookie-overlay">
        <div className="item-box">
            <button onClick={closeOverlayAndRejectConsent} className="close close-overlay">
                <CloseSVG />
            </button>
            <div className="overlay-container pd-t8">
                <div className="div">
                    <h4>Yes, I agree to the exchange of my data with the exhibitors of the event.</h4>
                    <p> As soon as I enter the virtual trade show area, I agree to my data (name, job title, company, e-mail) being passed on to the exhibitors at the event for the purpose of them sending me promotional information on the services they offer. This consent only applies to the companies whose content I have used. </p>
                    <p> Note from the organiser: Your privacy is very important to us. Therefore, we would like to inform you that when you interact with our partners, your business contact information will be shared with the partner. This is for the purpose of informing you about products or services. Please keep this in mind when communicating with or accessing content from our event partners. Your contact information will only be shared if you have agreed to the exchange with our partners. </p>
                    <p>
                        <a href="https://www.ccw.eu/en/data-privacy-statement/" target="_blank"><span> Privacy Policy </span></a>
                    </p>
                    <button id="accept-cookie" onClick={() => closeOverlayAndAcceptConsent(showOverlayOF)}>Yes, I agree</button>
                </div>
            </div>
        </div>
    </div>
    )
}
