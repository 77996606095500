import React, {createContext, useEffect, useState} from "react";
import { doRequest } from "../utils/doRequest";
import { isLogin } from "../utils";

export const LivestreamContext = createContext({
    livestream: [],
    setLivestream: () => {}
});

const LivestreamContextProvider = props => {
    const [livestream, setLivestream] = useState([]);
    
    // useEffect(() => {       
    //     let isSubscribed = true;
        
        
    //     if (isSubscribed && isLogin()) {
    //         doRequest(
    //             'React',
    //             'lib/storage/content/livestreams.json',
    //             'GET', 
    //             {},
    //             false,
    //             (err,data) => {
    //                     setLivestream(data);
    //             }
    //         );
    //     }
        
    //     return () => (isSubscribed = false)

    // }, []);

    return (
        <LivestreamContext.Provider value={{livestream: livestream, setLivestream: setLivestream}}>
            {props.children}
        </LivestreamContext.Provider>
    )
}

export default LivestreamContextProvider;