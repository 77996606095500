import React, { useContext, useEffect } from 'react'
import CloseSVG from '../Ui/CloseSVG'
import parse from "html-react-parser";
import overlayContext from '../../Context/overlayContext';
import { cryptedUserId, getCookie } from '../../utils';
import ConsetOverlay from './ConsetOverlay';
import { doRequest } from '../../utils/doRequest';

export default function PodcastOverlay() {

    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);

    function closeOverlay() {
        setShowoverlay('');
    }

    if(getCookie('consent') != 1){
        return(<ConsetOverlay showOverlayOF="podcast" />)
    }

    // useEffect(() => {
        console.log(overlayData)
        doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
            cryptedUserId : cryptedUserId(),
            company_id : overlayData.company,
            activity: 'podcast-' + overlayData.item.selectpodcast,
            title: overlayData.item.podcastheadline,
            eaagent: 0
          },'', (err, data) => {
            console.log('Analytics Reported')
          })
      
    // }, [])


    return (
        <div class="overlay scroll-overlay active" id="video-overlay">
            <div class="tx-exhibitor">
                
            <div class="item-box">
                <button className="close close-overlay" onClick={() => closeOverlay()} >
                    <CloseSVG />
                </button>
            <div class={`overlay-container ${overlayData.item.type}-content cl-white pd-t12 pd-b5`}>
                <div class="overlay-title flex-box center-left" onClick={() => {
                    if (document.getElementById("audio-frame")) {
                        document.getElementById("audio-frame").scrollIntoView();
                    }
                    if (document.getElementById("youtube-frame")) {
                        document.getElementById("youtube-frame").scrollIntoView();
                    }
                }}><strong>Podcast</strong></div>
                <div class="col-box col-2">
                    <div class="col-item">
                        <h3>{overlayData.item.podcastheadline}</h3>
                        {overlayData.item.podcastdescription && parse(overlayData.item.podcastdescription)}
                    </div>
                    <div class="col-item">

                        <figure class="image-box">
                                
                                {overlayData.item.image ? (
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS  + overlayData.item.image} />
                                ) : ''}
                                
                        </figure>
                    </div>
                </div>
                
                {overlayData.item.selectpodcast == 1 ? (
                    <div class="audio-box">
                        <audio id="audio-frame" controls>
                            <source src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + overlayData.item.podcast} type="audio/mpeg" />
                        </audio>
                    </div>
                ): (
                    <div class="iframe-box" >
                        <iframe id="youtube-frame" src={`https://www.youtube.com/embed/${overlayData.item.youtubelink}?version=3&enablejsapi=1`} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;" class="yvideo" allowfullscreen="" width="100%" height="513" frameborder="0"></iframe>
                    </div>
                )}



            </div>  
            </div>
            </div>
        </div>
    )
}
