import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";

import { decryptedUserId, getCookie, isLogin, jumpToSection } from '../utils';
import { doRequest } from "../utils/doRequest";

import { TopicsContext } from "../Context/TopicsContext";
import { useState } from "react";
import Countdown from 'react-countdown';
import moment from "moment-timezone";
import CloseSVG from "./Ui/CloseSVG";
import Chat from "../Chat/Chat";
import overlayContext from "../Context/overlayContext";
import { MyAgendaContext } from "../Live/Context/myAgendaContext";
import { ChatContext } from "../Context/ChatContext";

export default function Header({hideOptions , basic}) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { profile } = authContext;
    const [active, setActive] = useState('');
    
    const { topics, setTopics } = useContext(TopicsContext);

    const  [ showtopic , setShowTopics ] = useState(false);

    const { userAgenda } =  useContext(MyAgendaContext);
    const { chatCount } =  useContext(ChatContext);
    const [ chatActive, setChatActive] = React.useState('');

    const [ exhibitorAccess, setExhibitorAccess ] = React.useState(false);

    const [ showProfileMenu , setShowProfileMenu ] = useState(0);

    const localuserSearchTopics = JSON.parse(localStorage.getItem('searchTopicKey')) || [];
    const [userSearchTopics, setUserSearchTopics] = React.useState(localuserSearchTopics);

    const localtopicsName = JSON.parse(localStorage.getItem('searchTopicName')) || [];
    const [topicsName, setTopicName] = React.useState(localtopicsName);

    
    const [userAccess, setUserAccess] = useState(false)

    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);

    React.useEffect(() => {
      
        if(
                authContext?.profile?.usergroup?.includes('1') 
            ||  authContext?.profile?.usergroup?.includes('2')
        ) {
            setExhibitorAccess(true);
        }

        if(
            authContext?.profile?.usergroup?.includes('3') 
        ||  authContext?.profile?.usergroup?.includes('5')
        ) {
            setUserAccess(true);
        }
       
    }, [authContext?.profile])


    function HandleLogout() {
        authContext.logout();
    }

    React.useEffect(() => {
        if(profile.usergroup) {
            let usrgrp = profile.usergroup.split(',');
            if(usrgrp.includes('2') || usrgrp.includes('1')){
                setExhibitorAccess(true);
            };
        }
    }, [profile])

    React.useEffect(() => {
        localStorage.setItem('searchTopicKey', JSON.stringify(userSearchTopics));
    }, [userSearchTopics]);

    React.useEffect(() => {
        localStorage.setItem('searchTopicName', JSON.stringify(topicsName));
    }, [topicsName]);

    React.useEffect(() => {
        let isSubscribed = true;

            if(!hideOptions) {
                setInterval ( doBuzzing, 8000 );
                function doBuzzing ( ) {
                    if(document.querySelector('.matchmaking-counter') && document.querySelector('.matchmaking-counter').innerText != '0') {
                        document.getElementById('notification-opener').classList.add('buzzing');
                        setTimeout ( doSomething, 2000 );
                    }
                }
                function doSomething ( ) {
                    document.getElementById('notification-opener').classList.remove('buzzing');
                }    
            }

            if(topics.length != 0 && isSubscribed && isLogin()) {
                doRequest(
                    'React',
                    'lib/storage/content/Topics.json',
                    'GET', 
                    {v: (new Date()).getTime()},
                    false,
                    (err,data) => {
                        setTopics(data);
                    }
                );
            }
        

        return () => (isSubscribed = false)
    },[])

    
    if(basic) {
        return (<header id="masthead" className="site-header cf" role="banner">
                    <div className="branding cl-white">
                        <div className="item-box">
                            <Link className="logo" title="CCW" to="/"><span></span></Link>
                        </div>
                    </div>
                </header>
        )
    }

    if(!isLogin() || hideOptions) {
        const renderer = ({ completed, formatted }) => {
            if (completed) {
                return <></>; // Render a completed state
            } else {
              return (
                    <>
                    <div className="flex-box timer">
                            <span className="days">{formatted.days}</span>
                            <span className="hours">{formatted.hours}</span>
                            <span className="minutes">{formatted.minutes}</span>
                            <span className="seconds">{formatted.seconds}</span>
                    </div>
                    <div className="labels flex-box">
                        <span>Days</span>
                        <span>Hours</span>
                        <span>Minutes</span>
                        <span>Seconds</span>
                    </div>
                    </>
              )
            }
        };

        return (
            <header id="masthead" className="site-header cf" role="banner">
                <div className="branding cl-white">
                    <div className="item-box">
                        <Link className="logo" title="CCW" to="/"><span></span></Link>
                        <div className="meta-box flex-box center-right">
                            <span className="intro-time">
                                Conference digital 27 - 28 February 2024 <br />
                                Trade Show digital 27 - 29 February 2024 </span>
                            <div className="counter cl-white d_1" >
                                <div className="running wrap">
                                    <Countdown 
                                        date={parseInt(moment('2024-02-27T09:00:00').tz("Europe/Berlin").format("x"))}
                                        renderer={renderer}
                                    >
                                    </Countdown>
                                    <div className="text no-display"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }

    const handleChangeSearchTopic = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        
            
        const dataName = e.target.getAttribute('data-name');
        console.log(`${value} is ${checked} AND ${dataName}`);
            
        // Case 1 : The user checks the box
        if (checked) {
            setUserSearchTopics([...userSearchTopics, value]);
            setTopicName([...topicsName, dataName]);
        }
        // Case 2  : The user unchecks the box
        else {
            let newSearchTopic = userSearchTopics.filter((e) => e !== value);
            setUserSearchTopics(newSearchTopic);
            let newSearchTopicName = topicsName.filter((e) => e !== dataName);
            setTopicName(newSearchTopicName);
        }
    };

    function searchTopics() {
        const { topics } = userSearchTopics;
        setActive('');
        return navigate('/search-topics', { state: { topicsIds: localuserSearchTopics } });
    }

    const showtopics = () => {
        setShowTopics(!showtopic);
    }

    function toggleSearchTopic() {
        setActive(active === '' ? 'active' : '');
    }
    
    window.addEventListener('click', function(e){
        if (!document.querySelector('.user-profile-navi.active').contains(e.target) && !document.getElementById('user-profile').contains(e.target) && document.querySelector('.user-profile-navi.active') !== null){
            setShowProfileMenu('');
        }
    });

    return (
        <header id="masthead" className="site-header cf" key={'header'} role="banner">
        <div className="branding">
            <div className="item-box">
                {authContext.isAuth ? (
                    <Link className="logo" title="CCW" to="/live"><span></span></Link>
                ) : (
                    <Link className="logo" title="CCW" to="/"><span></span></Link>
                ) }
                
            <div className="meta-box flex-box center-right">
                <div className="topic-navi">
                <button className={`topic-filter ${active} `} onClick={toggleSearchTopic}  id="topic-navi">
                    <span className="topic-label"> Search for Topic</span>
                    {/* <span className="icon-box search"><i className="icon"></i></span> */}
                </button>
                <div className="filter-box filters">
                    <div id="topic-search-form">
                        <div></div>
                        <div className="form-row flex-box wrap">
                            {topics.length && topics.map((topic) => {
                                return (
                                    <div key={topic.uid} className="field-item standard-radio">
                                        <input
                                            type="checkbox"
                                            className="filter-topics-live"
                                            checked={userSearchTopics.includes(`${topic.uid}`) ? true : false}
                                            name="tx_ipswnews_newssearch[topic][]"
                                            id={`topic-${topic.uid}`}
                                            value={topic.uid}
                                            data-name={topic.name}
                                            onChange={handleChangeSearchTopic}
                                        />
                                        <label htmlFor={`topic-${topic.uid}`} className="checkbox-label">{topic.name}</label>
                                    </div>
                                )                            
                            })}
                        </div>
                        <div className="button-box">
                            <button
                                className="button call-to-action"
                                name="tx_ipswnews_newssearch[search]"
                                value="search"
                                onClick={searchTopics}
                            >
                                <span>Search</span>
                                <span className="icon-box search">
                                    <i className="icon"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                </div>

                <div className="matchmaking-navi-box flex-box center-center">
                    <Link hash="#agenda" to="/live" onClick={() => jumpToSection('#agenda-container')} className="button agenda">
                        <span className="matchmaking-label">my Agenda</span>
                        <span className="icon-box"><i className="icon agenda-counter"> {userAgenda.length} </i></span>
                    </Link>
                    <button className="matchmaking flex-box center-center" id="notification-opener" onClick={() => setShowoverlay('chat')}>
                        <span className="matchmaking-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5" viewBox="0 0 94 74" width="94px" height="74px">
                                <path d="M2028.52 128c0-10.502 8.48-10.502 8.48-10.502h66s10 0 10 10.502v44c0 8-10 8-10 8h-39l-20.56 14 6-14H2037s-8.48 0-8.48-8v-44Z" style={{"fill":"#ff9e00","stroke":"#ff9e00","strokeWidth":"2.89px"}} transform="matrix(-.69266 0 0 .6915 1497.57 -61)"></path>
                                <path d="M2028.52 128c0-10.502 8.48-10.502 8.48-10.502h66s10 0 10 10.502v44c0 8-10 8-10 8h-39l-20.56 14 6-14H2037s-8.48 0-8.48-8v-44Z" style={{"fill":"#000000", "stroke":"#ffffff", "strokeWidth":"2.28px"}} transform="matrix(.88163 0 0 .86928 -1786.91 -100.641)"></path>
                            </svg>
                        </span>
                        <span className="matchmaking-counter">{chatCount}</span>
                        <input type="hidden" id="currentRequests" value="0" />
                    </button>
                    <Link className="button call-to-action matchmaking-link" title="Matchmaker" to="/matchmaking">
                        <span className="matchmaking-label">Matchmaker</span>
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>
                </div>

                <div className="user-box">
                    {/* {profile && profile.image ? (
                        <button
                            className={`user-profile ${showProfileMenu ? 'active' : ''}`}
                            id="user-profile"
                            style={{ 'backgroundImage': "url('"+ process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + profile.image +"')" }}
                            onClick={() => setShowProfileMenu(!showProfileMenu)}
                            >
                            <span className="user-image icon-box"></span>
                        </button>            
                    ) : (
                        <button
                            className={`user-profile ${showProfileMenu ? 'active' : ''}`}
                            id="user-profile"
                            style={{ 'backgroundImage': "url('" + process.env.REACT_APP_ENDPOINT + "images/icon-user.svg')" }}
                            onClick={() => setShowProfileMenu(!showProfileMenu)}
                        >
                            <span className="user-image icon-box"></span>
                        </button>        
                    ) } */}
                    
                    <button
                        className={`user-profile ${showProfileMenu ? 'active' : ''}`}
                        id="user-profile"
                        style={{ 'backgroundImage': "url('" + process.env.REACT_APP_ENDPOINT + "images/hamburger.png')" }}
                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                        >
                        <span className="user-image icon-box"></span>
                    </button>  
                
                <div className="user-profile-navi active">
                    <Link className="my-contacts-link" to='/matchmaking'>
                        Matchmaker
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    <Link className="my-contacts-link" to='/live#livestream-container' onClick={() => setShowProfileMenu(!showProfileMenu)}>
                        Livestreams
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    {`${userAgenda.length}` > 0 ?
                        <Link to="/live#agenda-container" className="agenda-link" onClick={() => setShowProfileMenu(!showProfileMenu)}>
                            My Agenda
                            <span className="icon-box"><i className="icon"></i>
                            </span>
                        </Link>
                        : ''
                    }

                    <Link className="my-contacts-link" to='/live#program-container' onClick={() => setShowProfileMenu(!showProfileMenu)}>
                        Program
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    <Link className="my-contacts-link" to='/live#newsroom-section' onClick={() => setShowProfileMenu(!showProfileMenu)}>
                        Newsroom
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    <Link className="my-contacts-link" to='/live#exhibitor-gallery' onClick={() => setShowProfileMenu(!showProfileMenu)}>         
                        Exhibitors
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>
                    
                    {userAccess ? (
                        <>
                            <a href={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${getCookie('fe_typo_user')}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-editor`}>
                                Edit Profile
                                <span className="icon-box"><i className="icon"></i></span>
                            </a>
                        </>
                    ) : <></>}


                    {exhibitorAccess ? (                                
                        <>
                            <a href={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${getCookie('fe_typo_user')}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`}>
                                Edit Exhibitor Data
                                <span className="icon-box"><i className="icon"></i></span>
                            </a>
                        </>
                    ) : <></>}

                    
                    <Link className="my-contacts-link" to='/faq#v21' onClick={() => setShowProfileMenu(!showProfileMenu)}>
                        Help Chat
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    <a href="#!" onClick={() => HandleLogout()}>
                        Logout
                        <span className="icon-box"><i className="icon"></i></span>
                    </a>
                </div>
                </div>
            </div>
    
            </div>
        </div>
        </header>
    );
}