import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from '../Context/AuthContext';

const PrivateRoutes = () => {
    const authContext = useContext(AuthContext);
    if(authContext.profile.hasOwnProperty('livepage') && authContext.isAuth) {
        return authContext.profile.livepage == 1 ? <Outlet/> : <Navigate to="/user-login"/>
    }
    return(
        authContext.isAuth ? <Outlet/> : <Navigate to="/"/>
    )
}

export default PrivateRoutes