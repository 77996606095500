import SmallHero from "../Components/SmallHero";
import MatchFilter from "./MatchFilter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useEffect } from "react";

export default function Matchmaking () {

    useEffect(() => {
        document.body.classList.add('live');
        return () => { document.body.classList.remove('live'); }
    },[]);

    return (
        <div className="base-box fixed" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <MatchFilter type="bookmark" />
        </main>
        <Footer />
        </div>
    );
}
