import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import $ from "jquery";
import SmallHero from "./SmallHero";
import { jumpToSection } from "../utils";

export default function Faq() {
  useEffect(() => {
    document.body.classList.add("backend");
    document.body.classList.add("bd-user-profile");

    let search = window.location.hash;

    if (search) {
      jumpToSection(search);
    } else {
      jumpToSection("#root");
    }

    window.addEventListener(
      "hashchange",
      () => {
        let search = window.location.hash;

        if (search) {
          jumpToSection(search);
        }

        for (const li of document.querySelectorAll("h5.accordion-title")) {
          li.classList.remove("active");
        }

        if (window.location.hash) {
          $(window.location.hash).addClass("active");
        }
      },
      false
    );

    if (window.location.hash) {
      $(window.location.hash).addClass("active");
    }

    $(".accordion-title").on("click", function () {
      if (this.classList.contains("active")) {
        this.classList.remove("active");
      } else {
        this.classList.add("active");
      }
    });

    return () => {
      document.body.classList.remove("backend");
      document.body.classList.remove("bd-user-profile");
    };
  }, []);

  //   useEffect(() => {
  // 	let search = window.location.hash;
  // 	console.log(search)

  //     if(search) {
  //       jumpToSection(search);
  //     }

  //     for (const li of document.querySelectorAll("h5.accordion-title")) {
  //         li.classList.remove("active");
  //     }

  //     if(window.location.hash){
  //         $(window.location.hash).addClass('active')
  //     }

  //   }, [window.location.hash])

  return (
    <div class="base-box scroll-box" id="page-top">
      <Header hideOptions={true} basic={true} />
      <SmallHero />
      <div class="base-item cf">
        <main class="main-content">
          <section class="modul backend-form">
            <div class="item-box pd-5 ">
              <div class="tab-box flex-box wrap">
                <div className="nav-box flex-box pd-b3">
                  <div className="nav-tab">
                    <a
                      className="button-label"
                      style={{ textDecoration: "none" }}
                      href="#faq_visitors"
                    >
                      FAQs Visitors
                    </a>
                  </div>
                  <div className="nav-tab">
                    <a
                      className="button-label"
                      style={{ textDecoration: "none" }}
                      href="#faq_exhibitors"
                    >
                      FAQs Exhibitors
                    </a>
                  </div>
                </div>

                <div class="tab-content faq-box">
                  <h2 id="faq_visitors">FAQs Visitors</h2>
                  <h5 class="accordion-title" id="v1">
                    <span class="count">1. </span>Is there a guided tour of the
                    digital platform? <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the Video of CCWdigital for visitors here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/1_CCW_visitors-guided-tour-of-the-platform.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v2">
                    <span class="count">2. </span>How do I obtain access to the
                    platform? <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Everyone who has registered for CCW has access to the
                      platform. No ticket yet? Then click{" "}
                      <a target="_blank" href="https://www.ccw.eu/tickets.html">
                        here
                      </a>{" "}
                    </p>
                    <ul>
                      <li>
                        To visit the platform, you can either go directly to:{" "}
                        <a href="digital.ccw.eu">digital.ccw.eu</a> – or you use
                        the link, you will receive shortly before the CCW. (The
                        link will be sent to your email address with which you
                        registered originally for CCW.)
                      </li>
                      <li>
                        After you submitted your email address on the platform,
                        you will receive an access link in your inbox. Make sure
                        you also check your <strong>spam folder</strong>
                      </li>
                      <li>
                        By clicking on the access link, you will enter
                        CCWdigital
                      </li>
                      <li>
                        Please make sure that you have access to the email
                        account you <strong>originally registered with</strong>{" "}
                        also on your mobile devices (smartphones, tablets).
                        Especially if you want to use the digital platform from
                        Berlin
                      </li>
                      <li>
                        If you want to visit the platform with a device that
                        does not have access to your emails, you can also
                        forward the email with the access link to another email
                        address
                      </li>
                      <li>
                        Log in and create your individual profile. Show the
                        other participants who you are and what you are
                        interested in.
                      </li>
                    </ul>
                    <p>
                      Please watch the tutorial on how to register on CCWdigital
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/2_CCW_visitors-how-to-register.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v3">
                    <span class="count">3. </span>Do I get a password. Do I have
                    to log out? How can I log in again?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      No, you do not need a password. You can stay as long as
                      you want on the platform. If you want to log out, you can
                      just close the tab. To log back in, you simply repeat the
                      login process.
                    </p>
                    <p>
                      <strong>Important information:</strong> <br />
                      Whenever you have logged out of the digital platform, you
                      must request the login link again via the start page of
                      the digital platform. Your new login link will then be
                      sent to the e-mail address you originally used to
                      register.
                    </p>
                    <p>
                      Please make sure that you have access to the e-mail
                      account you <strong>originally registered with</strong>{" "}
                      also on your mobile devices (smartphones, tablets).
                      Especially if you want to use the digital platform from
                      Berlin.
                    </p>
                    <p>
                      Please watch the tutorial on how to register on CCWdigital
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/2_CCW_visitors-how-to-register.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v4">
                    <span class="count">4. </span>I did not receive a login
                    email? <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      There are three possible reasons why you did not receive a
                      login email:
                    </p>
                    <ol>
                      <li>
                        The login email was sent to your spam folder. Please,
                        check your spam folder!
                      </li>
                      <li>
                        Your email is not the one that was used to register.
                        Therefore, it is not stored in the backend, and you have
                        no authorization. Please use the email with which you
                        originally registered to CCW
                      </li>
                      <li>
                        Maybe the email you submitted was misspelled. Please,
                        try again.
                      </li>
                    </ol>
                  </div>

                  <h5 class="accordion-title" id="v5">
                    <span class="count">5. </span>What does the digital CCW
                    platform offer me as a visitor?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      When you are live in Berlin, the platform helps you to
                      find your way around at the CCW: Where and when is which
                      lecture taking place? Where can I find the exhibitors, I
                      am interested in? Who is all on site?
                    </p>
                    <p>
                      In addition, as a congress participant you can ask
                      questions to the speakers during the lectures and take
                      part in live votings.
                    </p>
                    <p>
                      The platform provides high quality recommendations on
                      lectures, exhibitors and content as well as networking
                      opportunities between all participants.
                    </p>
                    <ul>
                      <li>
                        Our filter function ensures that you as a visitor
                        precisely find the topics, products and services that
                        interest you.
                      </li>
                      <li>
                        Our matchmaking tool helps you as a visitor to exchange
                        ideas and network with visitors, exhibitors and speakers
                        who are right for you.
                      </li>
                      <li>
                        Text and video chat, including arranging appointments,
                        make your networking easier
                      </li>
                      <li>
                        The system also shows whether someone is currently
                        connected live from Berlin or remotely from wherever
                      </li>
                    </ul>
                    <p>
                      Please watch the Video of CCWdigital for visitors here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/1_CCW_visitors-guided-tour-of-the-platform.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v6">
                    <span class="count">6. </span>How do I complete my profile?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on how to complete your profile
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/3_CCW_visitors-how-to-complete-profile.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v7">
                    <span class="count">7. </span>Where do I see what is
                    currently being streamed live?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on how to get the live streams
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/4_CCW_visitors-how-to-get-the-livestreams.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v8">
                    <span class="count">8. </span>How can I set up my personal
                    agenda?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on how to set up your personal
                      agenda here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/5_CCW_visitors-how-to-set-up-your-personal-agenda.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v9">
                    <span class="count">9. </span>Where can I see all the
                    content of the partners and exhibitors?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on the newsroom and the company
                      spaces here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/6_CCW_visitors-how-to-find-content-of-partners-and-exhibitors.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v10">
                    <span class="count">10. </span>How do I find the content
                    that interest me?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on how to find my topics here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/7_CCW_visitors-how-to-search-for-content.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v11">
                    <span class="count">11. </span>How can I find partners and
                    exhibitors?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on partners and exhibitors here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/8_CCW_visitors-how-to-find-partners-and-exhibitors.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v12">
                    <span class="count">12. </span>How can I meet other visitors
                    and exhibitors online?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on how to use the matchmaker
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/9_CCW_visitors-how-to-use-the-matchmaker.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="v13">
                    <span class="count">13. </span>What technical requirements
                    do I have to fulfil? Do I need a special program or an app?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Have you already done the system check? Our{" "}
                      <a
                        target="_blank"
                        href="https://jitsi.ccw.eu/system-check/"
                      >
                        system check
                      </a>{" "}
                      is also located at the bottom in the footer area. There
                      you can check whether your computer fulfils all the
                      technical requirements.
                    </p>
                    <p>
                      The platform is browser-based and device-independent. It
                      works on all common devices. It can therefore also be used
                      by visitors and exhibitors on site and thus offers a host
                      of new possibilities in addition to proven
                      functionalities.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v14">
                    <span class="count">14. </span>Which parts of the program
                    can I watch in the live stream?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>Depending on what you have signed up for.</p>
                    <p>
                      <strong>Trade show:</strong> All lectures will be streamed
                      live.
                    </p>
                    <p>
                      <strong>Conference:</strong> The plenary on the two main
                      conference days will be streamed live. As a registered
                      conference participant, you can of course also follow the
                      trade show lectures via the live stream.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v15">
                    <span class="count">15. </span>Can I join the discussion and
                    ask questions during the lectures?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Yes, but only during the lectures at the conference. This
                      function is not available for lectures at the trade show.
                      If you would like to contact a speaker from the trade
                      show, simply use our matchmaker and send a message.
                      Alternatively, you can also visit the exhibitor's Company
                      Space.
                    </p>
                    <p>
                      <strong>For our conference members:</strong> If you are
                      live in Berlin, please open the livestream of the
                      conference. You can type your questions in the window next
                      to the livestream. To ask your first question you have to
                      click on the green button “Login with SSO”
                    </p>
                    <img
                      src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/CCW_Slido.png`}
                      width={"980px"}
                    />
                  </div>

                  <h5 class="accordion-title" id="v16">
                    <span class="count">16. </span>Why do I need the Matchmaker?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      The Matchmaker is the brain of the digital platform. It
                      works in a similar way to a business dating platform. A
                      top match, good match or match is created, depending on
                      how precisely interests and offers match.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v17">
                    <span class="count">17. </span>How do I find the exhibitor I
                    am looking for?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>All exhibitors can be filtered by name or product.</p>
                  </div>

                  <h5 class="accordion-title" id="v18">
                    <span class="count">18. </span>What is a Company Space?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Exhibitors present themselves, their offers and services
                      in digital Company Spaces. For example, by white papers or
                      articles. Get to know the experts and make video chat
                      appointments with the digital stand personnel.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v19">
                    <span class="count">19. </span>How long is the content of
                    the digital platform available?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      The platform will be available during the three days of
                      the tradeshow on 27 February until 29 February 2024. The
                      content of the platform will still be available for you
                      one week after CCW, until 8 March 2024.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v20">
                    <span class="count">20. </span>I have technical Problems?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Have you already done the system check? Our{" "}
                      <a
                        href="https://jitsi.ccw.eu/system-check/"
                        target="_blank"
                      >
                        system check
                      </a>{" "}
                      is also located at the bottom in the footer area. There
                      you can check whether your computer fulfils all the
                      technical requirements.
                    </p>
                    <p>
                      If something still does not work, please contact our
                      support team via the chat.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="v21">
                    <span class="count">21. </span>Do you have any other
                    problems?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      During the CCW trade show days (27 – 29 February) please
                      use our chat. Feel free to send a message to our support
                      team with a brief description of your problem via the
                      chat.
                    </p>
                    <p>
                      For questions before or after the CCW trade show days,
                      please contact our service team:
                      <br />
                      Management Circle
                      <br />
                      Phone:{" "}
                      <a href="tel:+49 (0) 61 96 / 4722 700">
                        +49 (0) 61 96 / 4722 700
                      </a>
                      <br />
                      Email:{" "}
                      <a href="mailto:registration@managementcircle.de">
                        registration@managementcircle.de
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-box flex-box wrap">
                <div class="tab-content faq-box">
                  <h2 id="faq_exhibitors">FAQs Exhibitors</h2>
                  <h5 class="accordion-title" id="e1">
                    <span class="count">1. </span>Is there a guided tour of the
                    digital platform? <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the Video of CCWdigital for Exhibitors here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/10_CCW_exhibitors-guided-tour-of-the-platform.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e2">
                    <span class="count">2. </span>How do I obtain access to the
                    platform? <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Everyone who has registered for CCW has access to the
                      platform. No ticket yet? Then click{" "}
                      <a href="https://www.ccw.eu/tickets/" target="_blank">
                        here
                      </a>
                      .
                    </p>
                    <ul>
                      <li>
                        To visit the platform you can either go directly to:{" "}
                        <a href="https://digital.ccw.eu/">digital.ccw.eu</a> -
                        or you use the link, you will receive shortly before the
                        CCW. (The link will be sent to your email address with
                        which you registered originally for CCW.)
                      </li>
                      <li>
                        After you submitted your email address on the platform,
                        you will receive an access link in your inbox. Make sure
                        you also check your <strong>spam folder</strong>
                      </li>
                      <li>
                        By clicking on the access link, you will enter
                        CCWdigital
                      </li>
                      <li>
                        Please make sure that you have access to the email
                        account you&nbsp;
                        <strong>originally registered with</strong> also on your
                        mobile devices (smartphones, tablets). Especially if you
                        want to use the digital platform from Berlin.
                      </li>
                      <li>
                        If you want to visit the platform with a device that
                        does not have access to your emails, you can also
                        forward the email with the access link to another email
                        address.
                      </li>
                      <li>
                        Log in and create your individual profile. Show the
                        other participants who you are and what you are
                        interested in.
                      </li>
                    </ul>
                    <p>
                      Please watch the tutorial on how to register on CCWdigital
                      here.
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/15_CCW_exhibitor-how-to-register.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e3">
                    <span class="count">3. </span>Do I get a password. Do I have
                    to log out? How can I log in again?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      No, you do not need a password. You can stay as long as
                      you want on the platform. If you want to log out, you can
                      just close the tab. To log back in, you simply repeat the
                      login process.
                    </p>
                    <p>
                      <strong>Important information:</strong>
                    </p>
                    <p>
                      Whenever you have logged out of the digital platform, you
                      must request the login link again via the start page of
                      the digital platform. Your new login link will then be
                      sent to the e-mail address you originally used to
                      register.
                    </p>
                    <p>
                      Please make sure that you have access to the e-mail
                      account you <strong>originally registered with</strong>{" "}
                      also on your mobile devices (smartphones, tablets).
                      Especially if you want to use the digital platform from
                      Berlin.
                    </p>
                    <p>
                      Please watch the tutorial on how to register on CCWdigital
                      here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/15_CCW_exhibitor-how-to-register.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <h5 class="accordion-title" id="e4">
                    <span class="count">4. </span>I did not receive a login
                    email?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      There are three possible reasons why you did not receive a
                      login email:{" "}
                    </p>
                    <ol>
                      <li>
                        The login email was sent to your spam folder. Please,
                        check your spam folder!
                      </li>
                      <li>
                        Your email is not the one that was used to register.
                        Therefore, it is not stored in the backend, and you have
                        no authorization. Please use the email with which you
                        originally registered to CCW.
                      </li>
                      <li>
                        Maybe the email you submitted was misspelled. Please,
                        try again.
                      </li>
                    </ol>
                  </div>
                  <h5 class="accordion-title" id="e5">
                    <span class="count">5. </span>How do I edit my Company
                    Space?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial on “how to edit your company
                      space” here
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/11_CCW_exhibitors-how-to-edit-my-company-space.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e6">
                    <span class="count">6. </span>Do I have the chance to
                    preview my Company Space?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Yes, absolutely. At the top of the “Edit Exhibitor Data”
                      page you can find the button “Preview Company Space”. Do
                      not forget to save your updates!{" "}
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e7">
                    <span class="count">7. </span>Is it possible to publish my
                    company space without uploading content (article,
                    whitepaper, pictures)?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      It is possible to publish your company space without
                      uploading content on all given channels. Nevertheless, we
                      recommend uploading as much valuable content as possible
                      to create benefits for your visitors and to get
                      high-profile leads.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e8">
                    <span class="count">8. </span>The
                    Whitepaper/Brochure/Article topics do not reflect the topic
                    of my content. What do I do?{" "}
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Classifying the various content offers makes it easier for
                      visitors to search for their specific topic of interest.
                      Therefore, it is helpful to choose the topic closest to
                      your content.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e9">
                    <span class="count">9. </span>Do I have a file restriction,
                    when uploading my content?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Yes, guidelines for your content have been sent to your
                      Company Space Admin via email.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e10">
                    <span class="count">10. </span>What is an “Exhibitor Company
                    Space Admin”?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      The “Exhibitor Company Space Admin” is able to edit the
                      company space page (incl.: uploading
                      whitepapers/articles). This Admin has extensive rights and
                      is able to create new “Digital Company Contact”. He/She
                      will receive an email with the login information after
                      he/she has been created. An “Company Space Admin” can also
                      create additional “Company Space Admins”.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e11">
                    <span class="count">11. </span>What is a “Digital Company
                    Contact”?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      The “Digital Company Contact” is the equivalent to the
                      stand personnel at a fair. To be available for interacting
                      with potential clients it might make more sense to not
                      register the physical stand staff onsite as digital stand
                      staff as well.
                    </p>
                    <p>
                      The number of "Digital Company Contacts" depends on your
                      booking: &nbsp;
                      <strong>
                        Basic 3 persons, Master 5 persons, Premium 7 persons.
                      </strong>
                    </p>
                    <p>
                      The "Digital Business Contact" can be booked by visitors
                      for video calls and can chat with visitors.
                    </p>
                  </div>

                  <h5 class="accordion-title" id="e12">
                    <span class="count">12. </span>I have been assigned “Digital
                    Company Contact”. What do I need to do in order to set up
                    this functionality in our Company Space?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial for “How to activate as a
                      Digital Company Contact”
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin/user_upload/CCW_Tutorials/12_CCW_exhibitors-how-to-activate-as-a-digital-company-contact.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e13">
                    <span class="count">13. </span>How can I set up my calendar
                    for video appointments?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      Please watch the tutorial for “How to set up my calendar
                      for video appointments”
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin//user_upload/CCW_Tutorials/13_CCW_exhibitors-how-to-set-up-my-calender-for-video-chat-appointments.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e14">
                    <span class="count">14. </span>What is “Leads and Analytics?
                    How do I get my digital Leads?
                    <span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      At the moment the platform is only accessible for
                      exhibitors. Therefore, no leads can be generated, and
                      “Leads &amp; Analytics” is locked until the event starts.
                      You will receive the contact details of all visitors who
                      have accessed your content via the digital platform.
                    </p>
                    <p>
                      Please watch the tutorial for “How do I receive my digital
                      Leads”
                    </p>
                    <video width="850" height="500" controls="controls">
                      <source
                        src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}fileadmin//user_upload/CCW_Tutorials/14_CCW_exhibtors-how-do-I-receive-my-digital-leads.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <h5 class="accordion-title" id="e15">
                    <span class="count">15. </span>Do you have any other
                    problems?<span class="icon-arrow-plus"></span>
                  </h5>
                  <div class="accordion-content">
                    <p>
                      During the CCW trade show days (27 – 29 February) please
                      use our chat.
                      <br />
                      Feel free to send a message to our support team with a
                      brief description of your problem via the chat.
                      <br />
                    </p>
                    <p>
                      For questions before or after the CCW trade show days,
                      please contact:
                      <br />
                      Management Circle
                      <br />
                      Ms. Elsa Muth
                      <br />
                      Phone:{" "}
                      <a href="tel:+49 61 96 / 4722 - 617">
                        +49 61 96 / 4722 - 617
                      </a>
                      <br />
                      Email:{" "}
                      <a href="mailto:elsa.muth@managementcircle.de">
                        elsa.muth@managementcircle.de
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <script type="text/javascript">
        {/* if(location.hash){
    $(location.hash).addClass('active')
} */}
      </script>

      <div class="overlay scroll-overlay" id="system-overlay">
        <div class="item-box">
          <button class="close close-overlay">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              stroke-miterlimit="10"
              clip-rule="evenodd"
              viewBox="0 0 41 41"
            >
              <g transform="translate(4.022 3.96)">
                <clipPath id="a">
                  <path
                    d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"
                    clip-rule="nonzero"
                  ></path>
                </clipPath>
                <g clip-path="url(#a)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    fill="none"
                    stroke="#fff"
                    transform="rotate(135 15.28 15.738)"
                  ></circle>
                  <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
          <div class="overlay-container"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
