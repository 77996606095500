import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import $ from 'jquery'
import { useState } from 'react'
import SmallHero from './SmallHero'
import { doRequest } from '../utils/doRequest'

export default function FeedbackPage() {

  useEffect(() => {
    document.body.classList.add('backend');
    document.body.classList.add('bd-user-profile');
    
    return () => {
      document.body.classList.remove('backend');
      document.body.classList.remove('bd-user-profile');
    }
  }, [])
  

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  const [ email, setEmail ] = useState('');
  const [ rating, setRating ] = useState('');
  const [ likeAboutCcw, setLikeAboutCcw ] = useState('');
  const [ quoteNameCompany, setQuoteNameCompany ] = useState('');
  const [ topicsExhibitorsPresentNextCcw, setTopicsExhibitorsPresentNextCcw ] = useState('');
  const [ whatCanWeDoBetter, setWhatCanWeDoBetter ] = useState('');
  const [ addedValueToYou, setAddedValueToYou ] = useState('');
  const [ reason, setReason ] = useState('');
  const [ submited, setSubmited ] = useState(false);
  const [ errors , setErrors ] = useState({email: false, firstTime: true});

  function handleSubmit(e) {

        let clickerror = {
            email: (email.length == 0 || !validateEmail(email)) ? true : false,
            firstTime: false
        };
        console.log(clickerror);

        setErrors(clickerror);

        let sercall = false;
        if (clickerror.email == false) {
            sercall = true;
        }

        e.preventDefault();

        if (sercall) {
            doRequest(
                'Server',
                'server.php?type=feedbackToHS',
                'POST', {
                    email: email,
                    nps_rating: rating,
                    what_do_you_like_about_ccw_: likeAboutCcw,
                    may_we_quote_you_with_name_and_company_: quoteNameCompany,
                    which_topics_and_exhibitors_should_be_presented_at_the_next_ccw_: topicsExhibitorsPresentNextCcw,
                    what_can_we_do_better_: whatCanWeDoBetter,
                    has_the_digital_platform_added_value_for_you_: addedValueToYou,
                    please_tell_us_the_reason_why_: reason
                },
                '',
                (err, data) => {
                    if (data.response.succ == 1) {
                        setSubmited(true);
                    }
                    setEmail('');
                    setRating('');
                    setLikeAboutCcw('');
                    setQuoteNameCompany('');
                    setTopicsExhibitorsPresentNextCcw('');
                    setWhatCanWeDoBetter('');
                    setAddedValueToYou('');
                    setReason('');
                }
            );
        }
    }

  return (
    <div class="base-box scroll-box" id="page-top">
        <Header hideOptions={true} basic={true} />
        <SmallHero />
        <main class="main-content">
        <section class="modul backend-form">
            <div class="item-box pd-t12 pd-b5" style={{"maxWidth" :"960px"}}>
                {!submited ? (
                    <form name="feedbackForm" onSubmit={handleSubmit} method="post">
                    <div class="form-box">
                        <h4>Your email-address:</h4>
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <input type="email" value={email} 
                                onChange={(e) => {setEmail(e.target.value); errors.email = (e.target.value == 0  || !validateEmail(e.target.value)) ? true : false;setErrors(errors);}}
                                name="email" id="js_email_feedback" class="required" />
                                <label class="field-label" for="email">Your email-address</label>
                            </div>
                        </div>
                        <div className="field-item txt-left">
                            {errors.email && <p className="cl-error no-margin">Please fill out this field with a valid email address</p>}
                        </div>

                        <h4 class="no-margin">How likely is it that you would recommend the CCW to colleagues?</h4>
                            <p class="info-text">0 = unlikely; 10 = very likely</p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio0" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="0" />
                                <label for="radio0" class="hashtag-label">0</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio1" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="1" />
                                <label for="radio1" class="hashtag-label">1</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio2" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="2" />
                                <label for="radio2" class="hashtag-label">2</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio3" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="3" />
                                <label for="radio3" class="hashtag-label">3</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio4" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="4" />
                                <label for="radio4" class="hashtag-label">4</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio5" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="5" />
                                <label for="radio5" class="hashtag-label">5</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio6" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="6" />
                                <label for="radio6" class="hashtag-label">6</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio7" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="7" />
                                <label for="radio7" class="hashtag-label">7</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio8" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="8" />
                                <label for="radio8" class="hashtag-label">8</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio9" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="9" />
                                <label for="radio9" class="hashtag-label">9</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio10" name="nps_rating" onChange={(e) => setRating(e.target.value)} value="10" />
                                <label for="radio10" class="hashtag-label">10</label>
                            </div>
                        </div>
    
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                            <h4 class="no-margin">What do you like about CCW?</h4>
                                {/* <label class="block-label regular" for="please_tell_us_the_main_reason_for_your_decision">What do you like about CCW?:</label> */}
                                <textarea name="what_do_you_like_about_ccw_" id="what_do_you_like_about_ccw_" onChange={(e) => setLikeAboutCcw(e.target.value)} class="required"></textarea>
                            </div>
                        </div>

                        <h4 class="no-margin">May we quote this with your name and company?</h4>
                        {/* <p class="info-text">Kann man das etwas kleiner setzen / einrücken?</p> */}
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio11" name="may_we_quote_you_with_name_and_company_" onChange={(e) => setQuoteNameCompany(e.target.value)} value="Yes" />
                                <label for="radio11" class="hashtag-label">YES</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio12" name="may_we_quote_you_with_name_and_company_" onChange={(e) => setQuoteNameCompany(e.target.value)} value="No" />
                                <label for="radio12" class="hashtag-label">NO</label>
                            </div>
                        </div>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item txt-left box-100">
                                <h4 class="no-margin">Which topics and exhibitors should be presented at the next CCW?</h4>
                                {/* <p class="info-text">Kann man das etwas kleiner setzen / einrücken?</p> */}
                                <textarea name="which_topics_and_exhibitors_should_be_presented_at_the_next_ccw_" id="which_topics_and_exhibitors_should_be_presented_at_the_next_ccw_" onChange={(e) => setTopicsExhibitorsPresentNextCcw(e.target.value)} class="required"></textarea>
                            </div>
                        </div>
                        
                        
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <h4 class="no-margin">What can we do better?</h4>
                                {/* <label class="block-label regular" for="was_there_anything_that_was_missing_in_terms_of_the_topics_covered">Please tell us the reason why:</label> */}
                                <textarea name="what_can_we_do_better_" id="what_can_we_do_better_" onChange={(e) => setWhatCanWeDoBetter(e.target.value)}></textarea>
                            </div>
                        </div>

                        <h4 class="no-margin">Has the digital platform added value for you?</h4>
                            {/* <p class="info-text">Kann man das etwas kleiner setzen / einrücken?</p> */}
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio15" name="has_the_digital_platform_added_value_for_you_" onChange={(e) => setAddedValueToYou(e.target.value)} value="Yes" />
                                <label for="radio15" class="hashtag-label">YES</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio16" name="has_the_digital_platform_added_value_for_you_" onChange={(e) => setAddedValueToYou(e.target.value)} value="No" />
                                <label for="radio16" class="hashtag-label">NO</label>
                            </div>
                        </div>

                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <h4 class="no-margin">Please tell us the reason why:</h4>
                                {/* <label class="block-label regular" for="was_there_anything_that_was_missing_in_terms_of_the_topics_covered">Please tell us the reason why:</label> */}
                                <textarea name="please_tell_us_the_reason_why_" id="please_tell_us_the_reason_why_" onChange={(e) => setReason(e.target.value)}></textarea>
                            </div>
                        </div>

                        <div class="button-box form-row flex-box">
                            <div class="field-item box-100 txt-right">
                                <button class="button call-to-action" name="submit" type="submit"><span class="button-label">Submit</span></button>
                            </div>
                        </div>
                    </div>
                </form>
                ) : 'Thank you for your feedback!'}
            </div>
            </section>
        </main>
        <Footer />
</div>
  )
}