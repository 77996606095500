import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { UserbackProvider } from '@userback/react';

ReactDOM.render(
  <React.StrictMode>
      {/* <UserbackProvider token="32926|62182|SpnD9Ma93dEJ7V6kvNp4npMq4"> */}
        <App />
      {/* </UserbackProvider> */}
  </React.StrictMode>
  ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
