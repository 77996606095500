import React from 'react'
import { Link } from 'react-router-dom'

export default function CsHeader({companyDetails}) {
  return (
    <section className="hero-content flex-box stretch bg-4">
    <div className="bg-image">
      <figure className="image-box">
        {(companyDetails.company.headerimage) ? (
          <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + companyDetails.company.headerimage} />
        ):(
          <img src={process.env.REACT_APP_ENDPOINT+'/images/bg-company-showroom.jpg'} />
        )}
      </figure>
    </div>
    <div className="item-box flex-box bottom-left">
      {/* <Link class="button" to={'/live#exhibitor-gallery'} >All Exhibitors</Link> */}
      <div className="flex-box intro-box bg-black cl-white position-relative">
        <figure className="image-box">
          {(companyDetails.company.logo) ? (
            <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + companyDetails.company.logo} />
          ): (
            <img src='/images/missing-logo.png' />
          )}
        </figure>
        <div className="text-box">
          <div className="member-badge txt-uppercase cl-white">
            <strong>{companyDetails.company.subcategory}</strong>
          </div>
          <p>
            <strong>{companyDetails.company.companyname}</strong>
            <br />
            {companyDetails.company.companyaddress}
            <br />
            {companyDetails.company.companypostal} {companyDetails.company.companycity}
            <br />
            {companyDetails.company.companycountry}
            <br />
            <a href={`tel:${companyDetails.company.companyphone}`}>{companyDetails.company.companyphone}</a>
            <br />
            <a href={companyDetails.company.companywebsite} target="_new">
            {companyDetails.company.companywebsite}
            </a>
            <br />
          </p>
        </div>
      </div>
    </div>
    <div className="member-badge-mobile txt-uppercase p-2 center-center flex-box">
          <strong>{companyDetails.company.subcategory}</strong>
    </div>
  </section> 
  )
}
