import * as React from "react";

import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { doRequest } from "../utils/doRequest";
import { BgCountDownImage } from "../Components/Ui/BgLiveImage";
import { jumpToSection } from "../utils";
import { BeatLoader } from "react-spinners";

const UserLoginForm = React.lazy(() => import("../Components/UserLoginForm"));
const UserLoginAccess = React.lazy(() =>
  import("../Components/UserLoginAccess")
);
const ThanksToSponsersSliderUserlogin = React.lazy(() =>
  import("../Components/ThanksToSponsersSliderUserlogin")
);

export default function UserLogin() {
  const authContext = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState(0);

  const loginHandler = () => {
    authContext.login();
  };
  const profileHandler = (profile) => {
    authContext.setProfile(profile);
  };

  const [isLoading, setLoading] = useState(0);

  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  function setCookie(cname, cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const hash = new URLSearchParams(location.search).get("hash");
  const status = new URLSearchParams(location.search).get("status");
  const redirectexhibitor = new URLSearchParams(location.search).get(
    "redirectexhibitor"
  );

  useEffect(() => {
    if (window.pageYOffset != 0) {
      jumpToSection("#root");
      setShowLoading(1);
    }
    if (window.pageYOffset == 0) {
      setShowLoading(0);
    }
    setTimeout(() => {
      setShowLoading(0);
    }, 100);
  }, [window.pageYOffset]);

  useEffect(() => {
    if (hash && !redirectexhibitor) {
      setLoading(1);
      doRequest(
        "Server",
        "server.php",
        "GET",
        {
          type: "confirmLogin",
          hash: hash,
          status: status,
        },
        "",
        (err, data) => {
          console.log(data);
          if (data.response == "Success" && data.responseArr.success) {
            let token = data.responseArr.response.session;
            let uid = data.responseArr.response.uid.toString();

            if (redirectexhibitor) {
              window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${token}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`;
            }

            setLoading(0);
            if (uid) {
              doRequest(
                "React",
                "/lib/storage/content/Users.json",
                "GET",
                {v: (new Date()).getTime()},
                "",
                (err, data) => {
                  if (data) {
                    let userFound = 1;
                    data.forEach((user) => {
                      if (uid == user.uid) {
                        userFound = 0;
                        var ciphertext = CryptoJS.AES.encrypt(
                          uid,
                          secretKey
                        ).toString();
                        setCookie("fe_typo_user", token);
                        sessionStorage.setItem("token", token);
                        sessionStorage.setItem("auth", ciphertext);
                        localStorage.setItem("contextVar24", ciphertext);
                        if (redirectexhibitor) {
                          window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${token}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`;
                        }
                        loginHandler();
                      }
                    });
                    if (userFound) {
                      doRequest(
                        "React",
                        "/lib/storage/content/Users2.json",
                        "GET",
                        {v: (new Date()).getTime()},
                        "",
                        (err, data1) => {
                          data1.forEach((user) => {
                            if (uid == user.uid) {
                              var ciphertext = CryptoJS.AES.encrypt(
                                uid,
                                secretKey
                              ).toString();
                              setCookie("fe_typo_user", token);
                              sessionStorage.setItem("token", token);
                              sessionStorage.setItem("auth", ciphertext);
                              localStorage.setItem("contextVar24", ciphertext);
                              if (redirectexhibitor) {
                                window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${token}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`;
                              }
                              loginHandler();
                            }
                          });
                        }
                      );
                    }
                  } else {
                    doRequest(
                      "React",
                      "/lib/storage/content/Users2.json",
                      "GET",
                      {v: (new Date()).getTime()},
                      "",
                      (err, data1) => {
                        data1.forEach((user) => {
                          if (uid == user.uid) {
                            var ciphertext = CryptoJS.AES.encrypt(
                              uid,
                              secretKey
                            ).toString();
                            setCookie("fe_typo_user", token);
                            sessionStorage.setItem("token", token);
                            sessionStorage.setItem("auth", ciphertext);
                            localStorage.setItem("contextVar24", ciphertext);
                            if (redirectexhibitor) {
                              window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${token}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`;
                            }
                            loginHandler();
                          }
                        });
                      }
                    );
                  }
                }
              );
            }
          } else {
            setLoading(0);
          }
        }
      );
    } else {
      if (hash) {
        doRequest(
          "Server",
          "server.php",
          "GET",
          {
            type: "confirmLogin",
            hash: hash,
            status: status,
          },
          "",
          (err, data) => {
            if (data.response == "Success" && data.responseArr.success) {
              let token = data.responseArr.response.session;
              let uid = data.responseArr.response.uid.toString();
              var ciphertext = CryptoJS.AES.encrypt(uid, secretKey).toString();
              setCookie("fe_typo_user", token);
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("auth", ciphertext);
              localStorage.setItem("contextVar24", ciphertext);
              window.location.href = `${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}user-login?ses_id=${token}&return_url=${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}exhibitor-editor`;
            } else {
              setLoading(0);
            }
          }
        );
      }
    }

    document.body.classList.add("countdown");
    return () => {
      document.body.classList.remove("countdown");
    };
  }, []);

  if (isLoading === 1) {
    return <div className="App">Loading...</div>;
  }

  if (showLoading) {
    return <BeatLoader />;
  }

  return (
    <div className="base-box login-home fixed" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <section className="hero-content flex-box stretch bg-1">
          <div className="item-box flex-box stretch full-size">
            <div className="bg-image">
              <figure className="image-box">
                <BgCountDownImage />
              </figure>
            </div>

            <div className="hero-item-box flex-box wrap center-center ">
              <div className="item-box">
                <section className="modul modul-intro-text pd-t12 pd-b4">
                  <div className="item-box">
                    <div className="text-box cl-white txt-center">
                      <h3>{process.env.REACT_APP_LOGIN_HERO_TEXT}</h3>
                      <p>{process.env.REACT_APP_LOGIN_HERO_SUB_TEXT}</p>
                    </div>
                  </div>
                </section>
                <section className="modul modul-start-login pd-t0 pd-b4">
                  <div className="item-box">
                    {authContext.isAuth ? (
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <UserLoginAccess />
                      </React.Suspense>
                    ) : (
                      <React.Suspense fallback={<div>Loading...</div>}>
                        <UserLoginForm />
                      </React.Suspense>
                    )}
                  </div>
                </section>
                <section className="modul modul-image-slider big-box pd-t0 pd-b5">
                  <div className="item-box">
                    <header className="modul-header txt-center">
                      <h3>Thanks to our Sponsors</h3>
                    </header>
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <ThanksToSponsersSliderUserlogin />
                    </React.Suspense>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
