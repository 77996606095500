export default function CloseSVG() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeMiterlimit="10"
        clipRule="evenodd"
        viewBox="0 0 41 41"
    >
        <g transform="translate(4.022 3.96)">
        <clipPath id="a">
            <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clipRule="nonzero"></path>
        </clipPath>
        <g clipPath="url(#a)">
            <circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)" ></circle>
            <path
            fill="none"
            stroke="#fff"
            strokeLinecap="square"
            d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"
            ></path>
        </g>
        </g>
    </svg>
  )
}