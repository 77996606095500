import * as React from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
  Outlet,
} from "react-router-dom";

import AuthContextProvider from "./Context/AuthContext";
import BookmarkContextProvider from "./Context/BookmarkContext";
import { OverlaysProvider } from "./Context/overlayContext";

import LivePage from "./Live/LivePage";
import ExhibitorLogin from "./Login/ExhibitorLogin";
import UserLogin from "./Login/UserLogin";

import Matchmaking from "./Matchmaking/Matchmaking";
import MyContacts from "./Matchmaking/MyContacts";
import Bookmarks from "./Matchmaking/Bookmarks";

import UserInfo from './Live/UserInfo';
import CompanyDetail from "./Live/CompanyDetail";
import SearchTopics from "./Live/SearchTopics";

import PublicPage from "./Login/PublicPage";
import Overlays from "./Components/Overlays";
import TopicsContextProvider from "./Context/TopicsContext";

import Chat from "./Chat/Chat";
import PrivateRoutes from "./utils/PrivateRoutes";
import LabelsContextProvider from "./Context/LabelsContext";
import Faq from "./Components/Faq"
import LivestreamContextProvider from "./Context/LivestreamContext";
import MyAgendaProvider from "./Live/Context/myAgendaContext";
import ChatContextProvider from "./Context/ChatContext";
import FeedbackPage from "./Components/FeedbackPage";
import FeedbackExhibitorPage from "./Components/FeedbackExhibitorPage";
import { jumpToSection } from "./utils";

const Hschat = () => {
  const location = useLocation();
  React.useEffect(() => {
    if(location.pathname != '/matchmaking'){
      window.scrollTo(0,0);
    }
    window?.HubSpotConversations?.widget?.refresh();
  }, [location])
  
  return(<Outlet />);
}

export default function App() {

  const [showOverlay, setShowoverlay] = React.useState('');
  const [overlayData, setOverlayData] = React.useState('');
  
  const [chatUserDetail, setChatUserDetail] = React.useState({});
  const [messageObj, setMessageObj] = React.useState({});

  return (
    <AuthContextProvider>
      <LabelsContextProvider>
        <TopicsContextProvider>
          <BookmarkContextProvider>
            <LivestreamContextProvider>
              <MyAgendaProvider>
                <ChatContextProvider value={{ chatUserDetail, setChatUserDetail, messageObj, setMessageObj }}>
                <OverlaysProvider value={{ showOverlay, setShowoverlay, overlayData, setOverlayData }}>
                <div className="env">{process.env.REACT_APP_ENV}</div>
                <Router>
                  <Routes>
                      <Route element={<Hschat />}>
                        <Route element={<PrivateRoutes />}>
                          <Route exact path="/live" element={<LivePage />} />
                          <Route exact path="/matchmaking" element={<Matchmaking />} />
                          <Route exact path="/userinfo/:userId" element={<UserInfo />} />
                          <Route exact path="/bookmarks" element={<Bookmarks />} />
                          <Route exact path="/my-contacts" element={<MyContacts />} />
                          <Route exact path="/company/:cmpName" element={<CompanyDetail />} />
                          <Route exact path="/search-topics" element={<SearchTopics />} />
                          <Route exact path="/chat" element={<Chat />} />
                        </Route>

                        <Route exact path="/" element={<PublicPage />} />
                        <Route exact path="/user-login" element={<UserLogin />} />
                        <Route exact path="/exhibitor-login" element={<ExhibitorLogin />} />
                        <Route exact path="/feedback" element={<FeedbackPage />} />
                        <Route exact path="/feedback_exhibitors" element={<FeedbackExhibitorPage />} />
                        <Route exact path="/faq" element={<Faq />} />
                        <Route path="/*" element={<UserLogin />} />
                      </Route>
                    </Routes>
                  </Router>
                  <Overlays />
                </OverlaysProvider>
                </ChatContextProvider>
              </MyAgendaProvider>
            </LivestreamContextProvider>
          </BookmarkContextProvider>
        </TopicsContextProvider>
      </LabelsContextProvider>
    </AuthContextProvider>
  );
}