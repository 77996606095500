import React from 'react'

export default function ShrinkSVG() {
  return (
    <svg
                    id="shrink"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    strokeMiterlimit="10"
                    clipRule="evenodd"
                    viewBox="0 0 41 41"
                >
                    <g transform="translate(4.022 3.96)">
                    <clipPath id="a">
                        <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"></path>
                    </clipPath>
                    <g clipPath="url(#a)">
                        <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="none"
                        stroke="#fff"
                        transform="rotate(135 15.28 15.738)"
                        ></circle>
                    </g>
                    </g>
                    <path
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.48635 .00028 -.00052 -.8882 26.522 26.716)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.00033 -.48634 .8882 -.0006 13.281 14.97)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.39344 .3873 -.62309 -.63297 31.283 19.96)"
                    ></path>
                    <g>
                    <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.48627 -.00827 .0151 .88806 14.059 12.877)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.00832 .48627 -.88806 .01519 27.49 24.403)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.38703 -.3937 .6334 .62265 9.41 19.71)"
                    ></path>
                    </g>
                </svg>
  )
}
