import React, { Fragment, useContext } from 'react'
import parse from "html-react-parser";
import { contentStyle } from '../../utils';
import { Link } from 'react-router-dom';
import overlayContext from '../../Context/overlayContext';

export default function Whitepaper({item, company,  isOverlay}) {
    let style = contentStyle(item.style);
    
    var bgClasses = ['bg-3', 'bg-9', 'bg-10', 'bg-black'];
    if(!item.class) {
      item.class = bgClasses[Math.floor(Math.random()*bgClasses.length)];
    }

    const { setShowoverlay, setOverlayData, overlayData } = useContext(overlayContext);

    function showOverlay() {
      setOverlayData({
        item: item,
        company: company
      });

      setShowoverlay('csWhitepaper');

    }
    
    return (
        <div className={`flex-item masonry-item ${style} company-live-ajax  ${item.class}`} >
    
              <div className="box-title">
              {isOverlay ? (
                  <button onClick={() => showOverlay()} className='button whitepaper-opener'>
                    <span className="button-label">Whitepaper Brochures</span>
                    <span className="icon-box play-movie"><i className="icon"></i></span>
                  </button>
                ) : (
                  <Link to={`/company/${item?.company?.uid}#our-content`} className="button whitepaper-opener">
                    <span className="button-label">Whitepaper Brochures</span>
                    <span className="icon-box whitepaper"><i className="icon"></i></span>
                  </Link>
                )} 
                  
              </div>
              

              <div className="text-box cl-white">
                    <h4>{item.title ? item.title : item.pdftitle}</h4>
                    {item.pdfdescription && parse(item.pdfdescription)}
              </div>
              <div className="product-overview-box cl-white">
                     {item.expertise && item.expertise.map((topic, index) => (
                        <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
                     ) )}
               </div>
      </div>
    )
}
