import React from 'react'

const overlayContext = React.createContext({
    overlay: '',
    showOverlay: '',
    overlayData: {},
    setOverlayData: () => {},
    setShowoverlay: () => {}
})
export const OverlaysProvider = overlayContext.Provider
export default overlayContext