import { useContext, useState } from "react";
import ProgramContext from "./Context/programContext";
import parse from "html-react-parser";
import axios from "axios";
import { useEffect } from "react";

import { cryptedUserId, decryptedUserId, jumpToSection } from "../utils";
import { doRequest } from "../utils/doRequest";
import { LabelContext } from "../Context/LabelsContext";
import { MyAgendaContext } from "./Context/myAgendaContext";
import { AuthContext } from "../Context/AuthContext";
import Rate from "../Components/Rate";

export default function AgendaSection({setShowloader}) {
  const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
  const { label } = useContext(LabelContext);
  const { programs } = useContext(ProgramContext);
  const [userId, setUserId] = useState(0);
  const [showAll, setShowAll] = useState(0);
  const [limit, setLimit] = useState(2);

  const [isOpen, setIsOpen] = useState([]);

  const authContext = useContext(AuthContext);
  const { profile, setProfile } = authContext;

  useEffect(() => {
    setShowAll(0);
    setLimit(2);
  }, [userAgenda]);

  useEffect(() => {
    setUserId(decryptedUserId());
  }, []);

  function toggleMyagenda(e) {
    setShowloader(true);
    doRequest(
      "Server",
      "server.php",
      "GET",
      {
        eventId: e.target.value,
        type: "myagenda",
        user: userId,
        checked: false,
      },
      "",
      (err, data) => {
        let type = "success";
        if (err) {
          if (data.error) {
            type = "error";
          }
        }
        if (data.error) {
          type = "error";
        }
        doRequest(
          "React",
          "lib/storage/users/" + userId + "/myagenda.json",
          "GET",
          {
            v: (new Date()).getTime()
          },
          "",
          (err, data) => {
            setUserAgenda(data.eventID);
            setShowloader(false);
          }
        );
      }
    );
  }

  function toggleAgendaContent(e) {
    let did = e.target.closest(".personal-event").getAttribute("data-id");

    if (!isOpen.includes(did)) {
      setIsOpen([...isOpen, did]);
    } else {
      let removeActiveclass = isOpen.filter(function (item) {
        return item !== did;
      });
      setIsOpen(removeActiveclass);
    }

    // if (e.target.closest(".personal-event").classList.contains("active")) {
    //   let removeActiveclass = isOpen.filter(function (item) {
    //     return item !== did;
    //   });

    //   setIsOpen(removeActiveclass);
    // } else {
    //   setIsOpen([...isOpen, did]);
    // }

    // console.log("isOpen", isOpen);

    // e.target.closest(".personal-event").classList.toggle("active");
    // e.target.closest(".accordion-title").classList.toggle("active");
  }

  function ShowRatings({ streamItem, speaker }) {
    if (profile.ratings && profile.ratings.length) {
      const rating = profile.ratings.find(
        (rating) =>
          rating.speaker === speaker.speakerId &&
          rating.stream === streamItem.eventId
      );

      if (rating) {
        return (
          <Rate
            initialValue={rating.rating}
            stream={streamItem.eventId}
            speaker={speaker.speakerId}
            message={"Agenda section"}
          />
        );
      }
    }

    return (
      <Rate
        stream={streamItem.eventId}
        speaker={speaker.speakerId}
        message={"Agenda section"}
      />
    );
  }

  function ItemHTML({ streamItem }) {
    let stageName = "";
    let streamClass = "";
    if (streamItem.stage == 1 || streamItem.stage == 2) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_1;
      streamClass = "tradeshow";
    }
    if (streamItem.stage == 3 || streamItem.stage == 4) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_2;
      streamClass = "speakersforum";
    }
    if (streamItem.stage == 5 || streamItem.stage == 6) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_3;
      streamClass = "conference";
    }

    if (!programs) {
      return <h1>Program Section Loading....</h1>;
    }

    return (
      <div
        className={`personal-event ${streamClass} ${
          isOpen.includes("" + streamItem.eventId) ? "active" : ""
        } `}
        data-id={streamItem.eventId}
      >
        <div
          className={`accordion-title flex-box 
        ${isOpen.includes("" + streamItem.eventId) ? "active" : ""}

        `}
        >
          <div className="personal-event-stageinfo">
            <div onClick={(e) => toggleAgendaContent(e)} className="stage-box">
              {label[streamItem.stage]}
            </div>
            <div className="time-box">
              <strong onClick={(e) => toggleAgendaContent(e)}>
                {" "}
                {streamItem.savedDate}
              </strong>
            </div>
          </div>
          <div className="personal-event-title">
            <div className="item-wrapper">
              <h3 onClick={(e) => toggleAgendaContent(e)}>
                {streamItem.detitle ? parse(streamItem.detitle) : ""}
                {streamItem.detitle && <br />}
                {streamItem.title ? parse(streamItem.title) : ""}
              </h3>
            </div>
          </div>
          <div className="delete-aganda-item">
            <input
              type="checkbox"
              checked=""
              className="event-hall-check"
              name={`event-hall-${streamItem.eventId}`}
              id={`event-hall-${streamItem.eventId}`}
              value={streamItem.eventId}
              onChange={(e) => toggleMyagenda(e)}
            />
            <span className="icon-box delete">
              <i className="icon"></i>
            </span>
          </div>
          <span
            onClick={(e) => toggleAgendaContent(e)}
            className="icon-box arrow agenda-toggle-arrow"
          >
            <i className="icon"></i>
          </span>
        </div>

        <div className="accordion-content">
          <div
            className="personal-event-info flex-box"
            style={{ "padding-bottom": 0 }}
          >
            {streamItem.dedescription && parse(streamItem.dedescription)}
            {parse(streamItem.description)}
            {streamItem.delongtext && parse(streamItem.delongtext)}
            {streamItem.longtext && parse(streamItem.longtext)}
          </div>
          <div
            className="personal-event-info flex-box"
            style={{ "padding-top": 0 }}
          >
            {/* <figure className="image-box">
                {speaker.image ?
                    <img src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}${speaker.image}`} />
                : <img src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg`} />}
              </figure> */}
            <div>
              {streamItem.speakers
                ? streamItem.speakers.map(
                    (speaker, index) =>
                      (speaker.firstname || speaker.lastname) && (
                        <p className="no-margin">
                          <strong className="speaker-name">
                            <span>
                              {speaker?.firstname} {speaker?.lastname},
                            </span>
                            <span className="speaker-position">
                              {" "}
                              {speaker?.position}
                            </span>
                            {speaker.company && (
                              <span className="company-name">
                                , {speaker?.company}
                              </span>
                            )}
                            <div>
                              {(streamItem.stage == 5 ||
                                streamItem.stage == 6) && (
                                <ShowRatings
                                  streamItem={streamItem}
                                  speaker={speaker}
                                />
                              )}
                            </div>
                            <br />
                          </strong>
                          {/* <strong className="company-name">{streamItem.company}</strong> */}
                        </p>
                      )
                  )
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ItemStream({ agendaId }) {
    if (programs) {
      return Object.values(programs).map((program) => {
        return Object.values(program).map((dates) => {
          return Object.values(dates).map((time) => {
            return Object.values(time).map((evt) => {
              if (evt.eventId == agendaId) {
                return <ItemHTML key={1} streamItem={evt} />;
              }
            });
          });
        });
      });
    }

    return <></>;
  }

  function showAllEvents() {
    setLimit(userAgenda.length);
    setShowAll(1);
  }
  function hideAllEvents() {
    jumpToSection("#agenda-container");
    setLimit(2);
    setShowAll(0);
  }

  function getAgendaPdf() {
    // setLoading(true);
    doRequest(
      "Server",
      "server.php",
      "GET",
      {
        type: "generateMyAgendaPdf",
        userid: cryptedUserId(),
        events: userAgenda,
      },
      true,
      (err, data) => {
        // console.log(data.length);
        // if (!data.length) return;
        if (data.error != 0) return;
        const fileName = "MyAgenda.pdf";
        downloadPdfFile(data.responseArr.pdfFileBase64, fileName);
        // const linkSource = `data:application/pdf;base64,${data.responseArr.pdfFileBase64}`;
        // const downloadLink = document.createElement("a");

        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
        // setLoading(false);
      }
    );
  }

  let downloadPdfFile = (pdfData, fileName) => {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var isChrome =
      navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
      navigator.vendor.toLowerCase().indexOf("google") > -1;
    var iOSVersion = [];
    if (iOS) {
      iOSVersion = navigator.userAgent
        .match(/OS [\d_]+/i)[0]
        .substr(3)
        .split("_")
        .map((n) => parseInt(n));
    }
    var attachmentData = pdfData;
    var attachmentName = fileName;
    var contentType = "application/pdf";

    var binary = atob(attachmentData.replace(/\s/g, ""));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    var linkElement = document.createElement("a");
    try {
      var hrefUrl = "";
      var blob = "";
      if (iOS && !isChrome && iOSVersion[0] <= 12) {
        blob = "data:application/pdf;base64," + pdfData;
        hrefUrl = blob;
      } else {
        if (iOS && !isChrome) {
          contentType = "application/octet-stream";
        }
        blob = new Blob([view], { type: contentType });
        hrefUrl = window.URL.createObjectURL(blob);
      }
      linkElement.setAttribute("href", hrefUrl);
      linkElement.setAttribute("target", "_blank");
      if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
        linkElement.setAttribute("download", attachmentName);
      }
      var clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      linkElement.dispatchEvent(clickEvent);
    } catch (ex) {}
  };

  return (
    <section
      style={{ background: "#fff", paddingTop: "2em" }}
      className="modul personal-events"
      id="agenda-container"
    >
      <div className="jumppoint" id="agenda"></div>
      {userAgenda.length != 0 && (
        <div className="item-box">
          <h5>My Agenda</h5>
          <div className="personal-events-box">
            <input type="hidden" className="totalStream" value="3" />
            {userAgenda
              ? userAgenda
                  .slice(0, limit)
                  .map((agendaId) => <ItemStream agendaId={agendaId} />)
              : ""}
          </div>
          {userAgenda.length == 2 || userAgenda.length == 1 ? (
            ""
          ) : !showAll && userAgenda.length > 2 ? (
            <div className="button-box txt-center pd-3">
              <button
                className="button open-all"
                id="personal-events-opener"
                onClick={(e) => showAllEvents(e)}
              >
                <span className="button-label">Show All</span>
                <span className="icon-box arrow">
                  <i className="icon"></i>
                </span>
              </button>
            </div>
          ) : (
            <div className="button-box txt-center pd-3">
              <button
                className="button close-all"
                id="personal-events-opener"
                onClick={(e) => hideAllEvents(e)}
              >
                <span className="button-label">Close</span>
                <span className="icon-box arrow">
                  <i className="icon"></i>
                </span>
              </button>
            </div>
          )}

          <div className="button-box txt-center pd-3">
            <button
              onClick={getAgendaPdf}
              className="call-to-action button create-pdf"
              id="create-agenda-pdf"
            >
              <span className="button-label">Create PDF from my Agenda</span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
