import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { BookmarkContext } from '../../Context/BookmarkContext';
import { decryptedUserId } from '../../utils';
import { doRequest } from '../../utils/doRequest';
import $ from 'jquery';
import axios from 'axios';
import overlayContext from '../../Context/overlayContext';
import { ChatContext } from '../../Context/ChatContext';


export default function MatchmkaingCard({item}) {

    const bookmarkContext = useContext(BookmarkContext);
    const { showOverlay, setShowoverlay } = useContext(overlayContext); 
    const { chatUserDetail, setChatUserDetail, messageObj, setMessageObj } =  React.useContext(ChatContext);

    let bookmarkActive = '';
    var tempBookmark = [];
    let matchClass = 'match';
    let username = `${item.first_name} ${item.last_name}`;
    let imageLink = process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + '' + item.image;

    if (bookmarkContext.bookmark?.indexOf(parseInt(item.uid)) != -1){
        bookmarkActive = 'active';
    }
    if(item.score >= 275 ) {
        matchClass = 'top-match';
    } else if(item.score >= 200  && item.score < 275) {
        matchClass = 'good-match';
    }

    function toggleBookmark(event) {
        event.preventDefault();
        let bookmarkId = parseInt(event.currentTarget.getAttribute('data-id'));
        
        let operation = 'add';
        if (bookmarkContext.bookmark.indexOf(bookmarkId) == -1) {
            bookmarkContext.setBookmark(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
            // setBookmarkUsers(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
        } else {
            tempBookmark = bookmarkContext.bookmark.filter(function(item) {
                return item !== bookmarkId
            });
            // setBookmarkUsers(tempBookmark);
            bookmarkContext.setBookmark(tempBookmark);
            operation = 'remove';
        }
        
        doRequest('Server', 'server.php', 'GET', { type: 'addBookmarkUser', user: decryptedUserId(),toUser: bookmarkId,operation: operation}, true, (err,data) => {
            console.log()
        })
    }


    function startChat(to, username, item) {
        setShowoverlay('chat');
        console.log(to);

        let toUser = to;
        var showDetail = true;
        var from = toUser;
        var online = item.onlineStatus;
        var to = decryptedUserId();

        let message = { to, from, online, showDetail, username };
        setMessageObj(message);
        document.body.classList.add('chat-detail');
        
        // setTimeout(() => {
        //     var chatFrame = $('.chat-notifications #overlay-iframe');
        //     chatFrame[0].contentWindow.postMessage(message, '*');    
        // }, 1000);

        axios.get(process.env.REACT_APP_SERVER_ENDPOINT + 'server.php', {
            headers: { 
              'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
            },
            auth: {
              username: 'cuatconference',
              password: 'relaunch'
            },
            params: {
              type: 'myContacts',
              user: decryptedUserId(),
              toUser: toUser

            }
          })
        .then(res=>{
           
        })
        .catch(err=>{
             // console.log(err);
        });

    }

    if(!item.activatematch) {
        return '';
    }
    return (
      <div key={item.uid} className={`matchmaking-results bg-white flex-box ${matchClass}`}>
                                    
                                    <div className="flex-item user-image">
                                        <figure className="image-box">
                                            <img src={imageLink}/>
                                        </figure>
                                    </div>
                                    
                                    <div className="flex-item flex-box user-information">
                                        <div className="user-information-item">
                                            <div className="user-nameset">
                                            <strong>{item.title} {item.first_name} {item.last_name}</strong>
                                            {item.position} {item.position ? <br /> : ''}
                                            {item.company}
                                            </div>
                                            <div className="user-status-freetext">
                                            <div>
                                                <strong>{item.userStatus?.anstext}</strong>
                                            </div>
                                            </div>
                                            <div className="user-status flex-box">
                                                {item.onlineStatus == 1
                                                    ? <span className="online-state">Online &amp; @CCW</span>
                                                    : ''
                                                }
                                                {item.onlineStatus == 2 
                                                    ? <span className="online-state">At Home</span>
                                                    : ''
                                                }
                                                {item.speaker == 1
                                                    ? <span className="speaker">Speaker</span>
                                                    : ''
                                                }
                                                {item?.usergroup?.includes('1') == 1
                                                || item?.usergroup?.includes('3') == 1
                                                    ? <span className="exhibitor">Exhibitor</span>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                    <div className="flex-item user-action txt-right">
                        <div className={`score-box ${matchClass}`}>
                            <span className={matchClass}>{matchClass}</span><span className="icon-box"><i className="icon"></i></span>
                        </div>
                        <div className="user-action-item">
                                                                            
                            
                            <button className={`button bookmark ${bookmarkActive}`} data-id={item.uid} onClick={toggleBookmark}>
                                <span className="button-label" data-id={item.uid}>Bookmark</span>
                                <span className="icon-box bookmarks" data-id={item.uid}><i className="icon"></i></span>
                            </button>
                            <Link to={'/userinfo/' + item.uid} className="button show-profile" >
                                <span className="button-label">Show Profile</span><span className="icon-box"><i className="icon"></i></span>
                            </Link>
                            <a onClick={() => {startChat(item.uid, username, item); setChatUserDetail(item);}} className="button call-to-action" href="#!"><span className="button-label" >Start Chat</span><span className="icon-box"><i className="icon"></i></span></a>
                        </div>
                    </div>
        </div>
  )
}
