import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BookmarkContext } from "../Context/BookmarkContext";

export default function Matchlinks() {

    const bookmarkContext = useContext(BookmarkContext);

    return (
        <>
            <NavLink to="/matchmaking"  
                className={(navData) => (navData.isActive ? "button nav-tab matchmaking-link active" : 'button nav-tab matchmaking-link')}
            >
                <span className="button-label">Matchmaking Search</span><span className="icon-box"><i className="icon"></i></span>
            </NavLink>

            <NavLink to="/my-contacts"
                className={(navData) => (navData.isActive ? "button nav-tab matchmaking-link active" : 'button nav-tab matchmaking-link')}
            >
                <span className="button-label">My Contacts</span><span className="icon-box"><i className="icon"></i></span>
            </NavLink>

            <NavLink to="/bookmarks" 
                className={(navData) => (navData.isActive ? "button nav-tab matchmaking-link active" : 'button nav-tab matchmaking-link')}
            >
                <span className="button-label">My Bookmarks</span><span className="icon-box bookmarks"><i className="icon"></i></span><span className="bookmark-counter">{bookmarkContext.bookmark.length}</span>
            </NavLink>
        </>
    );
}