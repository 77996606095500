import $ from 'jquery'
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Header from "../Components/Header";
import StickyLinks from "./StickyLinks";
import { doRequest } from "../utils/doRequest";
import { cryptedUserId, decryptedUserId, getCookie, jumpToSection, StreamName } from "../utils";
import moment from "moment/moment";

import CsFooter from "../Components/CompanySpace/CsFooter";
import CsHeader from "../Components/CompanySpace/CsHeader";

import Video from "../Components/Ui/Video";
import Podcast from "../Components/Ui/Podcast"
import Whitepaper from "../Components/Ui/Whitepaper"

import overlayContext from "../Context/overlayContext";
import { LabelContext } from "../Context/LabelsContext";
import { ChatContext } from '../Context/ChatContext';
import { MyAgendaContext } from './Context/myAgendaContext';
import { LivestreamContext } from '../Context/LivestreamContext';
import { AuthContext } from '../Context/AuthContext';
import Rate from '../Components/Rate';

export default function CompanyDetial() {
  let { cmpName } = useParams();

  const [showMap, setshowMap] = useState(false);
  const [companyDetails, setCompanyDetail] = useState();
  const [showmoreArticle ,setShowmoreArticle ] = useState(false);
  const { showOverlay, setShowoverlay, setOverlayData, overlayData } = useContext(overlayContext);
  const { chatUserDetail, setChatUserDetail, messageObj, setMessageObj } =  useContext(ChatContext);
  const { label } = useContext(LabelContext);
  const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
  const { setLivestream } = useContext(LivestreamContext);
  const authContext = useContext(AuthContext);
  const { profile, setProfile } = authContext;
  useEffect(() => {
    document.body.classList.add('company-page');
      if(window.location.hash) {
          jumpToSection(window.location.hash)
          window.history.replaceState(null, null, ' ');
      } else {
        jumpToSection('#root');
      }
      let isSubscribed = true;
      if (isSubscribed) {
        doRequest(
          'React',
          'lib/storage/content/livestreams.json',
          'GET', 
          {v: (new Date()).getTime()},
          false,
          (err,data) => {
              if (isSubscribed) {
                  setLivestream(data);
              }
          }
        );
      }

      const intervalId = setInterval(function () {
        doRequest(
          'React',
          'lib/storage/content/livestreams.json',
          'GET', 
          {v: (new Date()).getTime()},
          false,
          (err,data) => {
                setLivestream(data);
            }
          );
        }, 10000);


    return () => {
      isSubscribed = false;
      document.body.classList.remove('company-page');
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {

    doRequest('React', 'lib/storage/company/'+ cmpName +'.json', 'GET', {v: (new Date()).getTime()}, true, (err,data) => {
      setCompanyDetail(data)
    })

  }, [cmpName])


  function getBgcolor(index) {
    let bgColor;
    if(index % 1 == 0) {
      bgColor = 'bg-9'
    }
    if (index % 2 == 0) {
      bgColor = 'bg-10'
    }
    if (index % 3 == 0 || index % 7 == 0) {
      bgColor = 'bg-10';
    }
    if (index % 4 == 0) {
      bgColor = 'bg-3'
    }
    
    return bgColor;

  }

  function handleArticle() {
    if(getCookie('consent') != 1){
      setShowoverlay('articleConset');
    } else {
      if(!showmoreArticle) {
        setShowmoreArticle(true);
        doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
          cryptedUserId : cryptedUserId(),
          company_id : cmpName,
          activity: 'article',
          title: companyDetails.company.articletitle,
          eaagent: 0
        },'', (err, data) => {
          console.log('Analytics Reported')
        })
      } else {
        setShowmoreArticle(false)
      }
      
    }
  }

  useEffect(()=> {
    if(showOverlay == 'csArticle') {
      handleArticle();
    }
  }, [showOverlay]);

  function startChat(to, username) {
    setShowoverlay('chat');
    let toUser = to;
    var showDetail = true;
    var from = toUser;
    var to = decryptedUserId();

    doRequest('React', '/lib/storage/users/' + from + '/profile.json', 'GET', {v: (new Date()).getTime()}, true, (err, data) => {
      var online = data.onlineStatus;
      let message = { to, from, online, showDetail, username };
      setMessageObj(message);
    });
    document.body.classList.add('chat-detail');
    
    // setTimeout(() => {
    //     var chatFrame = $('.chat-notifications #overlay-iframe');
    //     chatFrame[0].contentWindow.postMessage(message, '*');    
    // }, 1000);

    doRequest('Server', 'server.php', 'GET', {
        type: 'myContacts',
        user: decryptedUserId(),
        toUser: toUser
    }, '', (err,data) => {console.log(data)} );

  }

  function toggleMyagenda(id, val) {
    let userId = decryptedUserId();
    let action = 'add';
    if(!val) { action = 'delete'; }

    doRequest('Server', 'server.php', 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
    }, true, (err,data) => {

        if(err) { console.log(err) }
        doRequest('React', "lib/storage/users/" + userId + "/myagenda.json", 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
          v: (new Date()).getTime()
        }, true, (err,data) => {
          setUserAgenda(data.eventID);
        });

      

    });

  
  }

  function ShowRatings({streamItem, speaker }) {
    if (profile.ratings && profile.ratings.length) {

      const rating = profile.ratings.find(
        (rating) => rating.speaker === speaker && rating.stream === streamItem.uid
      );
      if (rating) {
        return <Rate initialValue={rating.rating} stream={streamItem.uid} speaker={speaker} message={'Company Detail'} />;
      }
    }
    return <Rate stream={streamItem.uid} speaker={speaker} message={'Company Detail'} />;
  }


  return (
    <>
        <main className="main-content">
        <div className="base-box fixed scroll-box-company" id="page-top">
        <Header />
        <StickyLinks />
        {companyDetails && (
        <section id="matchPlaceHolder" className="company-page">
          <div className="tx-exhibitor">
            <div className="base-item company-page" data-company-id="27">
              <CsHeader companyDetails={companyDetails} />

              <main className="main-content">
                {companyDetails.company.mapimage && (
                  <section className="modul fair-map-box bg-4">
                    <div className="item-box txt-center">
                      <button className={`open-map ${showMap ? 'active': ''} `} onClick={() => setshowMap(!showMap)} id="open-fair-map">
                        <span className="icon-box locator">
                          <i className="icon"></i>
                        </span>
                        <strong className="open">Show on map</strong>
                        <strong className="close">Close Map</strong>
                        <span className="icon-box close">
                          <i className="icon"></i>
                        </span>
                      </button>
                      <figure className="image-box">
                        <img
                          src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + companyDetails.company.mapimage}
                        />
                      </figure>
                    </div>
                  </section>
                )}
            
              {companyDetails.company.productcat.length ? (
                <section class="modul product-categories-box pd-t8 pd-b4">
                  <div class="item-box">
                    <ul class="product-range flex-box wrap center-center">
                    {companyDetails.company.productcat.map((categories) => (
                        <li>{categories}</li>
                    ))}
                    </ul>
                  </div>
                </section>
              ) : ''}
                
                {companyDetails.company.aboutcompany && (
                  <section className="modul product-categories-box pd-4">
                    <div className="item-box txt-center small-box">
                      <div className="text-box">
                          {parse(companyDetails.company.aboutcompany)}
                      </div>
                    </div>
                  </section>
                )}

                {companyDetails.company.videochatagent && companyDetails.company.videochatagent.length ? (
                  <section className="modul staff-box pd-4 bg-black">
                    <div className="item-box cl-white">
                      <h5 className="txt-uppercase">Get in touch with our staff</h5>
                      <div className="col-box col-2">
                        {companyDetails.company.videochatagent.map((admin, index) => (
                          <div className={`col-item flex-box bg-9 ${getBgcolor(index)} `}>
                            <div className="flex-item image-item">
                                    <figure className="image-box">
                                      {admin.image ? (
                                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + admin.image} />
                                      ) : (
                                        <img src={process.env.REACT_APP_ENDPOINT+"/images/icon-user.svg"} />
                                      ) }
                                    </figure>
                            </div>
          
                            <div className="flex-item">
                              <div className="text-box flex-box flex-column">
                                <p className="no-margin">{admin.title}</p>
                                <h5 className="no-margin">{admin.firstName} <br /> {admin.lastName} </h5>
                                  <p><br /><strong>{admin.position}</strong></p> 
                                {admin.description && parse(admin.description)}
                                {admin.activatematch ?
                                  <Link class="button show-profile" to={`/userinfo/${admin.uid}`} >
                                    <span class="button-label">Get in touch</span><span class="icon-box"><i class="icon"></i></span>
                                  </Link>
                                  : 
                                  <button className="button call-to-action" onClick={() => {startChat(admin.uid, admin.firstName + ' ' + admin.lastName); admin.first_name = admin.firstName; admin.last_name = admin.lastName; setChatUserDetail(admin);}} data-id={admin.uid} >
                                    <span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                  </section>
                ):''}
                
                {companyDetails.company.articletitle && (
                  <section className="modul article-content-box pd-4 bg-9" id="article-content">
                     <div className={`item-box ${showmoreArticle? 'active' : ''} `}>
                        <div className="col-box col-2">
                          <div className="col-item">
                            {companyDetails.company.articleimage && (
                              <figure className="image-box">
                                <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + companyDetails.company.articleimage} />
                              </figure>
                            )}
                          </div>
                          <div className="col-item">
                            <div className="text-box cl-white">
                              <h3>{companyDetails.company.articletitle}</h3>
                              {parse(companyDetails.company.articleintro)}
                              <br />
                              <button onClick={() => handleArticle()} className={`button open-company-content ${showmoreArticle ? 'active': ''} `}>
                                {showmoreArticle ? (
                                  <strong className="close">show less...</strong>
                                  ) : (
                                  <strong className="open">show more...</strong>
                                )}
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="exhibitor-content cl-white">
                          {parse(companyDetails.company.articledescription)}
                        </div>
                    </div>
                  </section>
                )}
                
                {Object.keys(companyDetails.livestreams).length ? (
                  <section className="modul live-stream-box pd-5  bg-4">
                    <div className="item-box">
                      <h5 class="txt-uppercase">Live Performance</h5>
                      <div className="col-box col-3 ls-teaser-box cl-white">
                        {Object.values(companyDetails.livestreams).map((stream) => (
                            <div className={`col-item ${(stream.stage == 5 || stream.stage == 6 )? 'conference': 'tradeshow'} cl-white stage-${stream.stage}`}>
                              <div className="content-box">
                                <p><strong>{label[stream.stage]}</strong></p>
                                
                                <h3>{stream.savedDate}</h3>

                                <div className="field-item standard-radio">
                                  <input
                                    type="checkbox"
                                    className="event-hall-check"
                                    name={`event-hall-${stream.eventId}`}
                                    id={`event-hall-${stream.eventId}`}
                                    value={stream.eventId}
                                    checked={
                                      userAgenda &&
                                      userAgenda.includes(stream.eventId) &&
                                      "checked"
                                    }
                                    onChange={() => {}}
                                  />
                                  <label
                                    onClick={(e) => 
                                      toggleMyagenda(
                                        stream.eventId,
                                        userAgenda.includes(stream.eventId) ? false : true
                                      )
                                    }
                                    htmlFor={`event-hall-${stream.eventId}`}
                                    className="checkbox-label"
                                  >
                                    Add to MyAgenda
                                  </label>
                                </div>

                                <h3>{stream.detitle && <>{parse(stream.detitle)}<br/><br/></>}
                                  {stream.title}</h3>
                                  {stream.dedescription && parse(stream.dedescription)}
                                  {stream.description && parse(stream.description)}
                                <br />

                                {stream.speakers.map((speaker,key) => <>
                                  <p>{speaker}</p>

                                  {(stream.stage == 5 || stream.stage == 6) && (
                                    <ShowRatings streamItem={stream} speaker={stream.speakersUids[key]}  />
                                  )}
                                </>)}
                                
                                <div className="button-box record-available">
                                {stream.is_livestream ? (
                                <p>No Livestreaming</p>
                                ) : (
                                      stream.starttimestamp <= moment().tz("Europe/Berlin").unix() 
                                      &&  moment().tz("Europe/Berlin").unix() <= stream.endtimestamp 
                                      ?
                                      (
                                        (stream.stage == 5 || stream.stage == 6) ? (
                                          (authContext.profile.conferenceuser ? (
                                            (stream.dehtmllivestream ? (
                                              <>
                                                <a 
                                                  onClick={() => {
                                                    setOverlayData({
                                                      stream: stream.uid, 
                                                      streamUrl: stream.streamUrl, 
                                                      companyId : stream?.companyId, 
                                                      stage: stream.stage,
                                                      title: stream.title,
                                                      showConsent : true,
                                                    stageTitle: <StreamName stream={stream} />
                                                  }); setShowoverlay('livestream')}}
                                                  style={{cursor: 'pointer'}}
                                                  >
                                                  Start Live Stream (en)
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <a 
                                                  onClick={() => {
                                                    setOverlayData({
                                                      stream: stream.uid, 
                                                      streamUrl: stream.streamUrl, 
                                                      companyId : stream?.companyId, 
                                                      stage: stream.stage,
                                                      title: stream.title,
                                                      showConsent : true,
                                                      showDeStream: true,
                                                    stageTitle: <StreamName stream={stream} />
                                                  }); setShowoverlay('livestream')}}
                                                  style={{cursor: 'pointer'}}
                                                  >
                                                  Start Live Stream (de)
                                                </a>
                                              </>
                                            ) : (
                                              <a 
                                                onClick={() => {
                                                  setOverlayData({
                                                    stream: stream.uid, 
                                                    streamUrl: stream.streamUrl, 
                                                    companyId : stream?.companyId, 
                                                    stage: stream.stage,
                                                    title: stream.title,
                                                    showConsent : true,
                                                  stageTitle: <StreamName stream={stream} />
                                                }); setShowoverlay('livestream')}}
                                                style={{cursor: 'pointer'}}
                                                >
                                                Start Live Stream
                                              </a>
                                            ))
                                          ) : (
                                            // Conference User, can not watch live stream
                                            ''
                                          ))
                                        ) : 
                                        (
                                        <a 
                                          onClick={() => {
                                            setOverlayData({
                                              stream: stream.uid, 
                                              streamUrl: stream.streamUrl, 
                                              companyId : stream?.companyId, 
                                              stage: stream.stage,
                                              title: stream.title,
                                              showConsent : true,
                                            stageTitle: <StreamName stream={stream} />
                                          }); setShowoverlay('livestream')}}
                                          style={{cursor: 'pointer'}}
                                          >
                                          Start Live Stream
                                        </a>
                                        )
                                      )
                                      :
                                      moment().tz("Europe/Berlin").unix() <= stream.starttimestamp  
                                      ?
                                      (<span className="strong">Livestream starts soon</span>) 
                                      :
                                      (
                                        stream.archivemp4? (
                                          stream.stage == 5 ? (
                                            authContext.profile.conferenceuser == 1 ? (
                                              <>
                                                <a 
                                                onClick={() => {
                                                  setOverlayData({
                                                    stream: stream.uid, 
                                                    streamItem: stream.streamUrl, 
                                                    companyId : stream?.companyId, 
                                                    stage: stream.stage,
                                                    title: stream.title,
                                                    archivelive: stream.archivemp4, 
                                                    showConsent : true
                                                  }); setShowoverlay('archiveLivestream')}}
                                                  className="button call-to-action live-stream-opener recent-stream"
                                                  style={{cursor: 'pointer'}}
                                                  >
                                                  Show Recording
                                                </a>
                                              </>
                                            ) : (
                                              <a target="_blank" style={{ textDecoration: 'none' }} className="button call-to-action live-stream-opener recent-stream" href="https://www.ccw.eu/tickets/kongress/">
                                                View recording <br />
                                                Become a Conference Member <br />
                                                (Werden Sie Konferenzmitglied)
                                              </a>
                                            )
                                          )
                                          : (
                                            <a 
                                            onClick={() => {
                                              setOverlayData({
                                                stream: stream.uid, 
                                                streamItem: stream.streamUrl, 
                                                companyId : stream?.companyId, 
                                                stage: stream.stage,
                                                title: stream.title,
                                                archivelive: stream.archivemp4, 
                                                showConsent : true,
                                              }); setShowoverlay('archiveLivestream')}}
                                              style={{cursor: 'pointer'}}
                                              className="button call-to-action live-stream-opener recent-stream"
                                              >
                                              Show Recording
                                            </a>
                                          )
                                        ):
                                        <span className="strong">Recording will be available soon</span>
                                      ) 
                                )}
                                </div>
                              </div>
                            </div>
                        ))}
                      </div>
                    </div>
                  </section>
                ): ''}
                
                {Object.keys(companyDetails.hotstreams).length && JSON.stringify(companyDetails.hotstreams) == "{}" ? (
                  <section class="modul whats-hot-box pd-4">
                  <div class="item-box">
                    <h5 class="txt-uppercase flex-box wrap">Whats hot hour <span class="regular subline txt-reset ">,</span></h5>
                    <figure class="image-box open-stream-box live-stream-opener">
                        {Object.values(companyDetails.hotstreams).map((stream) => (
                          <div className={`col-item tradeshow cl-white stage-${stream.stage}`}>
                              <div className="content-box">
                                <p><strong><StreamName stream={stream} /></strong></p>
                                {stream.starttimestamp? <h3>{moment(stream.starttimestamp).tz("Europe/Berlin").format("HH:mm")}</h3> : '' }
                                {stream?.title ? <h3>{stream?.title}</h3> : ''}
                                {stream.longtext ? parse(stream.longtext) : ''}
                                <div className="button-box record-available">Recording will be available soon</div>
                              </div>
                            </div>
                        ))}
                      {/* <!-- Attention this is only an image that has a javascript function on it to open a livestream window...-->*/}
                    </figure>
                  </div>
                </section>
                ): ''}

                {companyDetails.ourContent.length ? (
                  <section className="modul exhibitor-masonry-box pd-4 bg-black" id="our-content">
                  <div className="item-box">
                    <h5 class="txt-uppercase cl-white">Our Content</h5>
                    <ResponsiveMasonry
                          columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1024: 4}}
                      >
                      <Masonry  columnsCount={4} className="masonry">
                        {companyDetails.ourContent.map((content) => {
                          if (content.type == 'video' && content.videoheadline) {
                            return <Video company={cmpName} item={content} isOverlay={true} />;
                          }
                          if (content.type == 'podcast' && content.podcastheadline) {
                            return <Podcast company={cmpName} item={content} isOverlay={true} />;
                          }
                          if (content.type == 'whitepaper' && content.pdftitle) {
                            return <Whitepaper company={cmpName} item={content} isOverlay={true} />;
                          }
                        })}
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                </section>
                ) : ''}
                

                {companyDetails.company.relatedcompany.length ? (
                  <section class="modul pd-4 bg-4">
                    <div class="item-box">
                        <h5 class="txt-uppercase">Partner</h5>
                        <div class="sorting-box" id="company-box">
                          <div class="col-box logo-gallery">
                              
                              {companyDetails.company.relatedcompany.map((company) => (
                                <div title={company.companyname} class="col-item txt-center bg-white filtering-item">
                                  
                                  <Link to={'/company/' + company.uid } target={"_blank"}></Link>
                                  
                                  <figure class="image-box exhibitor-category silver">
                                      <img alt={company.companyname} src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + company.logo} />
                                  </figure>
                                  
                                </div>
                              ))}
                              
                          </div>
                        </div>
                    </div>
                  </section>
                ) : ''}

              </main>
            </div>

            <CsFooter companyDetails={companyDetails} />
          </div>           
        </section>
        )}
        </div>
        </main>
    </>
  );
}
