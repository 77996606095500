import React, { useContext, useState } from 'react'
import { AuthContext } from '../../Context/AuthContext';
import { doRequest } from '../../utils/doRequest';

export default function ExhibitorOverlay() {
  const authContext = useContext(AuthContext);
  const [ submited, setSubmited ] = useState(false);
  const doVoting = (voting) => {
    doRequest(
        'Server',
        'server.php?type=votingToHS',
        'POST', {
            email: authContext.profile.email,
            voting: voting
        },
        '',
        (err, data) => {
            if (data.response.succ == 1) {
                setSubmited(true);
            }
        }
    );
  };
  return (
    <>
        <div className="overlay-title flexbox center-left"></div>
        <div className="col-box">
            <div className="col-item">
                {/* <h3>Bewertung Conference</h3> */}
                <h3>KI-Panel: Die Highlights aus der CCW-Messe auf der Kongressbühne vereint!</h3>
                <p>Zukunftsweisende KI-Lösungen für den Kundenservice im Schnelldurchlauf. Wen möchten Sie kennenlernen? Wählen Sie jetzt!</p>
                <p>Drei Anbieter haben sich bereits im Vorfeld qualifiziert. Über den Vierten entscheiden Sie! Wen möchten Sie im Kongress hören? Stimmen Sie noch bis zum Mittag des ersten Kongresstages darüber ab, wer am zweiten Kongresstag auf der Bühne stehen soll!</p>
            </div>
        </div>
        {!submited ? (
            <>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/U.SU_500x500.jpg`} alt="USU Knowledge Center" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3>USU Knowledge Center</h3>
                        <p>Durch den Einsatz von USU Knowledge Center zusammen mit „large language Modellen“ ändert sich der Wissensmanagement-Prozess grundlegend.</p>
                        <p>Wissensdatenbanken wurden bisher von Menschen für Menschen betrieben. Entsprechend aufwändig ist ihre Nutzung und entsprechend subjektiv ihr Nutzen.</p>
                        <p>Mit Generativer AI wird sich das ändern. Aus der künstlerischen Erstellung gebrauchsfertigen Wissens wird der mechanisierte Prozess, der über automatisierte und teilautomatisierte Prozessschritte Wissensobjekte produziert und nutzt. Knowledge Center von USU wird ab jetzt von Menschen und Maschinen für Menschen und Maschinen betrieben werden. Damit stehen wir an der Schwelle zur Industrialisierung von Wissensprozessen in Unternehmen. Knowledge Center ist einer der ersten Innovatoren, mit dem dieser Schritt sichtbar wird. Aufgaben, die früher viele Stunden in Anspruch genommen haben, werden von der AI übernommen und direkt umgesetzt.</p>
                        <button onClick={() => doVoting('Vote for USU')}>Vote for USU</button>
                    </div>
                </div>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/S.pitch.jpg`} alt="Spitch" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3>Spitch</h3>
                        <p>Bei Spitch sind wir Pioniere im Verstehen natürlicher Sprache in all ihren Facetten - sei es in Dialekten oder grammatikalischen Variationen, genauso, wie Menschen im Alltag sprechen. Unser Fokus liegt nicht nur auf den Wörtern, sondern vor allem auf der tiefen Bedeutung hinter den Aussagen. Stellen Sie sich vor, jemand sagt: 'Ich kündige Ihnen schon mal an, dass ich nächste Woche vorbeikomme.' Unser Anspruch ist es, zu erkennen, dass es um die Planung eines Termins geht, nicht um eine Kündigung, obwohl die Wortwahl eine andere Richtung vermuten lässt.</p>
                        <p>Unsere Kunden profitieren von unserer aussergewöhnlich hohen Trefferquote, da Anrufer stets zu den passenden Experten weitergeleitet werden. Bei uns gibt es keine erzwungenen Generalisten mehr, die alles wissen müssen, oder langwierige Komplettausbildungen für Neueinsteiger, bevor sie Kunden helfen können. Die Effizienz ist bei uns oberstes Gebot, was zu einer spürbaren Entlastung der Mitarbeiter und einer höheren Kundenzufriedenheit führt.</p>
                        <p>Als mittelständisches Schweizer Unternehmen sind wir eng mit unseren Kunden verbunden und verstehen ihre digitale Reise im Bereich telefonischer Beratung bestens. Dank unserer eigenen Technologie und hochskalierbaren Systeme, sei es vor Ort oder in der Cloud, unterstützen wir namhafte Kunden bereits seit 2014.</p>
                        <p>Drücken Sie 1 oder 2 oder 3' gehört der Vergangenheit an. Die Zukunft lautet: «Bitte schildern Sie uns Ihr Anliegen.»</p>
                        <button onClick={() => doVoting('Vote for Spitch')}>Vote for Spitch</button>
                    </div>
                </div>
                <div className="col-box col-2">
                    <div className="col-item">
                        <figure class="image-box">
                            <img src={`${process.env.REACT_APP_ENDPOINT}/images/S.OGEDES_500x500.jpg`} alt="Sogedes" />
                        </figure>
                    </div>
                    <div className="col-item">
                        <h3>Sogedes</h3>
                        <p>Stellen Sie sich vor, Sie könnten in jeder beliebigen Sprache auf der Welt Menschen Ihren Service anbieten – ohne neue Mitarbeiter einstellen zu müssen! Das wäre für viele Service Center ein großes Plus, denn oftmals sind Sprachbarrieren sehr hinderlich. Die Berücksichtigung sprachlicher Vielfalt im Service Center stellt Unternehmen und Behörden vor eine große Herausforderung. Mitarbeitende mit unterschiedlichen Sprachkompetenzen werden benötigt, um eine immer breiter werdende Nachfrage abzudecken. Das ist kostenintensiv und vor allem ist qualifiziertes Personal mit unterschiedlichen Sprachkompetenzen heute schwer zu finden. Mit unserem KI-basierten Service Portal können Sie diese Sprachbarrieren einfach durchbrechen! Unser Portal hilft, dass Menschen sich in unterschiedlichen Sprachen unterhalten können, und gleichzeitig Standardanfragen automatisiert in der Sprache der Wahl beantwortet werden können oder Sprachnachrichten hinterlassen werden können, die dann in die Sprache des Agenten übersetzt und in Textform zur Verfügung gestellt werden kann.</p>
                        <p>In unserem Beispiel ruft ein Kunde, der nur Chinesisch spricht, in einem deutschen Service Center an: <a title="Service Center" target="_blank" href="https://www.youtube.com/watch?v=bKnxolAmczI">https://www.youtube.com/watch?v=bKnxolAmczI</a></p>
                        <button onClick={() => doVoting('Vote for Sogedes')}>Vote For Sogedes</button>
                    </div>
                </div>
            </>
        ) : (
            <div className="col-box">
                <div className="col-item">
                    <p>Thanks for your vote!!!</p>
                </div>
            </div>
        ) }
    </>
  )
}
