import React from "react";

function BgLiveImage() {
  return (
    <img
      srcSet={`${process.env.REACT_APP_ENDPOINT}/images/bg-livepage.jpg 790w, 
           ${process.env.REACT_APP_ENDPOINT}/public/images/bg-livepage.jpg 1080w, 
           ${process.env.REACT_APP_ENDPOINT}/public/images/bg-livepage.jpg 1680w`}
      sizes="(max-width: 1680px) 100vw, 1680px"
      alt="Background Image"
      src={`${process.env.REACT_APP_ENDPOINT}/public/images/bg-livepage.jpg`}
    />
  );
}

function BgCountDownImage() {
  return (
    <img
      srcSet={`${process.env.REACT_APP_ENDPOINT}/public/images/bg-Spotlight-Buehne-790.jpg 790w, 
       ${process.env.REACT_APP_ENDPOINT}/public/images/bg-Spotlight-Buehne-1080.jpg 1080w, 
       ${process.env.REACT_APP_ENDPOINT}/public/images/bg-Spotlight-Buehne-1680.jpg 1680w`}
      alt="Background Count down image"
      sizes="(max-width: 1680px) 100vw, 1680px"
      src={`${process.env.REACT_APP_ENDPOINT}/public/images/bg-homepage.jpg`}
    />
  );
}

export { BgLiveImage, BgCountDownImage };
