import React, { useContext, useEffect } from 'react'
import CloseSVG from '../Ui/CloseSVG'
import parse from "html-react-parser";
import overlayContext from '../../Context/overlayContext';
import { cryptedUserId, getCookie } from '../../utils';
import ConsetOverlay from './ConsetOverlay';
import { doRequest } from '../../utils/doRequest';

export default function WhitepaperOverlay() {

    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);
    function closeOverlay() {
        setShowoverlay('');
    }

    if(getCookie('consent') != 1){
        return(<ConsetOverlay showOverlayOF="csWhitepaper" />)
    }

    // useEffect(() => {
        console.log(overlayData)
        doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
            cryptedUserId : cryptedUserId(),
            company_id : overlayData.company,
            activity: 'whitepaper-' + overlayData.item.number,
            title: overlayData.item.pdftitle,
            eaagent: 0
          },'', (err, data) => {
            console.log('Analytics Reported')
          })
      
    // }, [])


    return (
        <div class="overlay scroll-overlay active" id="video-overlay">
            <div class="tx-exhibitor">
                
            <div class="item-box">
                <button className="close close-overlay" onClick={() => closeOverlay()} >
                    <CloseSVG />
                </button>
            <div class={`overlay-container ${overlayData.item.type}-content cl-white pd-t12 pd-b5`}>
                <div class="overlay-title flex-box center-left">
                    <strong>Whitepaper Brochures</strong>
                </div>
                <div class="col-box col-2">
                    <div class="col-item">
                        <h3>{overlayData.item.pdftitle}</h3>
                        {overlayData.item.pdfdescription && parse(overlayData.item.pdfdescription)}
                        
                    </div>
                    <div class="col-item flex-box bottom-center">
                        <a href={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + overlayData.item.pdfpath} target="_blank" class="button call-to-action">Jetzt downloaden</a>
                    </div>
                </div>
              

            </div>  
            </div>
            </div>
        </div>
    )
}
