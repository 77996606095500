import React from 'react'

export default function CsFooter({companyDetails}) {
  return (   
        <footer id="colophon" class="site-footer clear bg-black" role="contentinfo">
            {/* <!-- ATTENTION:
                this Section is only visible on the company page...  --> */}
            <a href="#page-top" class="totop"></a>
            <a href="#page-top" class="totop"></a>
            <section class="footer-meta-box bg-1">
                {/* <!-- OUTPUT: Company Image, same as in the hero header --> */}
                <div class="bg-image">
                <figure class="image-box">
                    <img src={process.env.REACT_APP_ENDPOINT+'/images/bg-company-showroom.jpg'} />
                </figure>
                </div>
                <div class="item-box pd-b5 pd-t8">
                    <div class="flex-box top-left footer-content-box">
                        <div class="flex-item box-40">
                        <p class="no-margin cl-white">
                            <strong>{companyDetails.company.companyname}</strong>
                            <br />
                            {companyDetails.company.companyaddress}
                            <br />
                            {companyDetails.company.companypostal} {companyDetails.company.companycity}
                            <br />
                            {companyDetails.company.companycountry}
                            <br />
                            <a href={`tel:${companyDetails.company.companyphone}`}>{companyDetails.company.companyphone}</a>
                            <br />
                            <a href={companyDetails.company.companywebsite} target="_new">
                            {companyDetails.company.companywebsite}
                            </a>
                            <br />
                        </p>
                        <nav class="footer-nav company-nav">
                            {console.log(companyDetails.company)}
                            <a href={companyDetails.company.companyimpressumlink} target="_blank"><span>Imprint</span></a>
                            <a href="https://www.ccw.eu/en/legal-notice/" target="_blank"><span>Legal Notice CCW</span></a>
                            <a href="https://www.ccw.eu/en/data-privacy-statement/" target="_blank"><span>Data Privacy CCW</span></a>
                            {/* <!-- OUTPUT Company Datenschutz Link --> */}
                        </nav>
                        </div>
                        <div class="flex-item box-60">
                        <div class="iframe-box">
                            {/* <!-- OUTPUT Googlelink -->*/}
                                        <iframe 
                                        src={companyDetails.company.googlemaplink} 
                                        style={{'border' : '0' }}
                                        allowfullscreen="" 
                                        aria-hidden="false" 
                                        tabindex="0" 
                                        width="600" 
                                        height="450" 
                                        frameborder="0"></iframe>
                                    
                        </div>
                        </div>
                    </div>
                </div>
            </section>
            </footer>
    )}
