import React, { useContext, useEffect } from 'react'
import overlayContext from '../../Context/overlayContext';
import CloseSVG from '../Ui/CloseSVG'
import parse from "html-react-parser";
import ConsetOverlay from './ConsetOverlay';
import { cryptedUserId, getCookie, jumpToSection } from '../../utils';
import { doRequest } from '../../utils/doRequest';

export default function VideoOverlay() {
    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);
    
    function closeOverlay() {
        setShowoverlay('');
    }

    if(getCookie('consent') != 1){
        return(<ConsetOverlay showOverlayOF="video" />)
    }

    // useEffect(() => {
        doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
            cryptedUserId : cryptedUserId(),
            company_id : overlayData.company,
            activity: 'video-' +  overlayData.item.selectvideo,
            title: overlayData.item.videoheadline,
            eaagent: 0
          },'', (err, data) => {
            console.log('Analytics Reported')
          })
    // }, [])
    
    


    return (
        <div class="overlay scroll-overlay active" id="video-overlay">
            <div class="tx-exhibitor">
                
            <div class="item-box">
                <button className="close close-overlay" onClick={() => closeOverlay()} >
                    <CloseSVG />
                </button>
            <div class={`overlay-container ${overlayData.item.type}-content cl-white pd-t12 pd-b5`}>
                <div class="overlay-title flex-box center-left" onClick={() => {
                    if (document.getElementById("video-mp4")) {
                        document.getElementById("video-mp4").scrollIntoView();
                    }
                    if (document.getElementById("youtube-frame")) {
                        document.getElementById("youtube-frame").scrollIntoView();
                    }
                }}><strong>Video</strong></div>
                <div class="col-box col-2">
                    <div class="col-item">
                        <h3>{overlayData.item.videoheadline}</h3>
                        {overlayData.item.videodescription && parse(overlayData.item.videodescription)}
                    </div>
                    <div class="col-item">
                        <figure class="image-box">
                                {overlayData.item.image ? (
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS  + overlayData.item.image} />
                                ) : ''}
                        </figure>
                    </div>
                </div>
                
                {overlayData.item.selectvideo == 1 ? (
                    <div class="video-box">
                    <video id="video-mp4" class="video-mp4"  width="100%" height="513" controls>
                        <source src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + overlayData.item.video} type="video/mp4" />
                    </video>
                </div>
                ): (
                    <div class="iframe-box" >
                        <iframe id="youtube-frame" src={`https://www.youtube.com/embed/${overlayData.item.youtubelink}?version=3&enablejsapi=1`} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;" class="yvideo" allowfullscreen="" width="100%" height="513" frameborder="0"></iframe>
                    </div>
                )}
		
            </div>  
            </div>
            </div>
        </div>
    )
}
