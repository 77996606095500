import { useState } from "react";
import { useContext, useEffect } from "react";
import { LivestreamContext } from "../Context/LivestreamContext";
import overlayContext from "../Context/overlayContext";
import { cryptedUserId } from "../utils";
import { doRequest } from "../utils/doRequest";
import parse from "html-react-parser";

export default function ArchiveVideo() {
	const { overlayData, setShowoverlay } = useContext(overlayContext);
	
	function sendAnalyticsStream(company_id, activity, title,eaagent) {
		console.log(company_id,'Report analysis');
	
		company_id.forEach(element => {

			doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
				cryptedUserId : cryptedUserId(),
				company_id : element.companyid,
				activity: activity,
				title: title,
				eaagent: eaagent
			  },'', (err, data) => {
				console.log(data);
			  })
			
		});

	}

	useEffect(() => {
		sendAnalyticsStream(overlayData.companyId , 'stream' , overlayData.title, 0 );
		console.log(overlayData);
	}, [overlayData])

	
    return (
		<div id="stream-video-container">
			<div className="col-box col-2 live-stream-box">
				<div className={`col-item box-70 fullwidth `}>				
					{overlayData.showDeStream ? (
						overlayData.dearchivelive ? (
							<div className="overlay-iframe">
								<div className="video-box">
									<div className="inner">
									<div className="ce-textpic ce-center ce-above">
										<div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
										<div className="ce-outer">
											<div className="ce-inner">
											<div className="ce-row">
												<div className="ce-column">
												<figure className="video">
													<div className="video-embed">
														<video
															width="1500"
															controls
															no-cookie
															className="video-embed-item"
															id="video-mp4"
														>
															<source
																src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS+overlayData.dearchivelive}
																type="video/mp4"
															/>
														</video>
													</div>
												</figure>
												</div>
											</div>
											</div>
										</div>
										</div>
									</div>
									</div>
								</div>
							</div>
							// <div className="iframe-box" style={{"height":"100%" }}>
							// 	<iframe id="vimeo-player" src={overlayData.archivelive} width="600" height="100%"  allow="autoplay; fullscreen" allowFullScreen="" style={{"height": "100%"}}></iframe>
							// </div>
						) : ''
					) : (
						overlayData.archivelive ? (
							<div className="overlay-iframe">
								<div className="video-box">
									<div className="inner">
									<div className="ce-textpic ce-center ce-above">
										<div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
										<div className="ce-outer">
											<div className="ce-inner">
											<div className="ce-row">
												<div className="ce-column">
												<figure className="video">
													<div className="video-embed">
														<video
															width="1500"
															controls
															no-cookie
															className="video-embed-item"
															id="video-mp4"
														>
															<source
																src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS+overlayData.archivelive}
																type="video/mp4"
															/>
														</video>
													</div>
												</figure>
												</div>
											</div>
											</div>
										</div>
										</div>
									</div>
									</div>
								</div>
							</div>
						) : ''
					)}
					
					<h5 className="cl-4"> {overlayData.stageTitle} {overlayData.title && parse(overlayData.title)}</h5>
					<h5 className="cl-4"> {overlayData.stageTitle} {overlayData.detitle && parse(overlayData.detitle)}</h5>
				</div>
				
				{/* {(activestream.stage == 5 || activestream.stage == 6) ? (
					<div className="col-item chat-box bg-3 box-30">
					{activestream.stage == 5 && (
						<iframe src="https://app.sli.do/event/19bzwPCr4aKL6FR3NkQdXj" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
					
					{activestream.stage == 6 && (
						<iframe src="https://app.sli.do/event/19bzwPCr4aKL6FR3NkQdXj" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
				</div>
				) : ('')} */}

			</div>
		
			
			
			<script src="https://player.vimeo.com/api/player.js"></script>
		</div>
    )
}