import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import $ from 'jquery'
import { useState } from 'react'
import SmallHero from './SmallHero'
import { doRequest } from '../utils/doRequest'

export default function FeedbackPage() {

  useEffect(() => {
    document.body.classList.add('backend');
    document.body.classList.add('bd-user-profile');
    
    return () => {
      document.body.classList.remove('backend');
      document.body.classList.remove('bd-user-profile');
    }
  }, [])
  

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const [ email, setEmail ] = useState('');
  const [ rateSatisfyWithEvent, setRateSatisfyWithEvent ] = useState('');
  const [ rateSatisfyWithNewContact, setRateSatisfyWithNewContact ] = useState('');
  const [ rateSatisfyWithQualityNewContact, setRateSatisfyWithQualityNewContact ] = useState('');
  const [ likeAboutCcw, setLikeAboutCcw ] = useState('');
  const [ quoteNameCompany, setQuoteNameCompany ] = useState('');
  const [ whatCanWeDoBetter, setWhatCanWeDoBetter ] = useState('');
  const [ addedValueToYou, setAddedValueToYou ] = useState('');
  const [ reason, setReason ] = useState('');
  
  const [ submited, setSubmited ] = useState(false);
  const [ errors , setErrors ] = useState({email: false, firstTime: true});

  function handleSubmit(e) {

    let clickerror = {
        email: (email.length == 0 || !validateEmail(email)) ? true : false,
        firstTime: false
    };

    setErrors(clickerror);

    let sercall = false;
    if (clickerror.email == false) {
      sercall = true;
    }

    e.preventDefault();

    if (sercall) {
        doRequest(
            'Server',
            'server.php?type=exhibitorFeedbackToHS',
            'POST', {
                email: email,
                how_satisfied_have_you_been_with_the_event_: rateSatisfyWithEvent,
                how_satisfied_have_you_been_with_the_number_of_new_contacts_: rateSatisfyWithNewContact,
                how_satisfied_have_you_been_with_the_quality_of_new_contacts_: rateSatisfyWithQualityNewContact,
                what_do_you_like_about_ccw_: likeAboutCcw,
                may_we_quote_you_with_name_and_company_: quoteNameCompany,
                what_can_we_do_better_: whatCanWeDoBetter,
                has_the_digital_platform_added_value_for_you_: addedValueToYou,
                please_tell_us_the_reason_why_: reason
            },
            '',
            (err, data) => {
                if (data.response.succ == 1) {
                    setSubmited(true);
                }
                setEmail('');
                setRateSatisfyWithEvent('');
                setRateSatisfyWithNewContact('');
                setRateSatisfyWithQualityNewContact('');
                setLikeAboutCcw('');
                setQuoteNameCompany('');
                setWhatCanWeDoBetter('');
                setAddedValueToYou('');
                setReason('');
            }
        );

        // $.ajax({
        //         url: 'https://api.live-ipserviceworld.com/?type=1000233233',
        //         type: 'post',
        //         data: {
        //             email: email,
        //             nps_rating: rating,
        //             please_tell_us_the_main_reason_for_your_decision_: cover,
        //             which_topics_would_you_like_us_to_cover_during_our_next_ip_serviceworld_: reason
        //         },
        //         success: function(content) {
        //             const obj = JSON.parse(content);
        //             console.log(obj);
        //             if (obj.succ == 1) {
        //                 setSubmited(true);
        //             }
        //             console.log(content);
        //             setEmail('');
        //             setRating();
        //             setReason('');
        //             setCover('');
        //         }
        //     });
        }
    }

  return (
    <div class="base-box scroll-box" id="page-top">
        <Header hideOptions={true} basic={true} />
        <SmallHero />
        <main class="main-content">
        <section class="modul backend-form">
            <div class="item-box pd-t12 pd-b5" style={{"maxWidth" :"960px"}}>
                {!submited ? (
                    <form name="feedbackForm" onSubmit={handleSubmit} method="post">
                    <div class="form-box">
                        <h4>Your email-address:</h4>
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <input type="email" value={email} 
                                onChange={(e) => {setEmail(e.target.value); errors.email = (e.target.value == 0  || !validateEmail(e.target.value)) ? true : false;setErrors(errors);}}
                                name="email" id="js_email_feedback" class="required" />
                                <label class="field-label" for="email">Your email-address</label>
                            </div>
                        </div>

                        <div className="field-item txt-left">
                            {errors.email && <p className="cl-error no-margin">Please fill out this field with a valid email address</p>}
                        </div>

                        <h4 class="no-margin">How satisfied have you been with the event?</h4>
                            <p class="info-text">0 = not satisfied; 10 = very satisfied</p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_0" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="0" />
                                <label for="how_satisfied_have_you_been_with_the_event_0" class="hashtag-label">0</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_1" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="1" />
                                <label for="how_satisfied_have_you_been_with_the_event_1" class="hashtag-label">1</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_2" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="2" />
                                <label for="how_satisfied_have_you_been_with_the_event_2" class="hashtag-label">2</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_3" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="3" />
                                <label for="how_satisfied_have_you_been_with_the_event_3" class="hashtag-label">3</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_4" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="4" />
                                <label for="how_satisfied_have_you_been_with_the_event_4" class="hashtag-label">4</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_5" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="5" />
                                <label for="how_satisfied_have_you_been_with_the_event_5" class="hashtag-label">5</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_6" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="6" />
                                <label for="how_satisfied_have_you_been_with_the_event_6" class="hashtag-label">6</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_7" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="7" />
                                <label for="how_satisfied_have_you_been_with_the_event_7" class="hashtag-label">7</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_8" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="8" />
                                <label for="how_satisfied_have_you_been_with_the_event_8" class="hashtag-label">8</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_9" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="9" />
                                <label for="how_satisfied_have_you_been_with_the_event_9" class="hashtag-label">9</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_event_10" name="how_satisfied_have_you_been_with_the_event_" onChange={(e) => setRateSatisfyWithEvent(e.target.value)} value="10" />
                                <label for="how_satisfied_have_you_been_with_the_event_10" class="hashtag-label">10</label>
                            </div>
                        </div>
    
                        <h4 class="no-margin">How satisfied have you been with the number of new contacts?</h4>
                            <p class="info-text">0 = not satisfied; 10 = very satisfied</p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_0" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="0" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_0" class="hashtag-label">0</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_1" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="1" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_1" class="hashtag-label">1</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_2" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="2" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_2" class="hashtag-label">2</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_3" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="3" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_3" class="hashtag-label">3</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_4" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="4" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_4" class="hashtag-label">4</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_5" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="5" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_5" class="hashtag-label">5</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_6" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="6" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_6" class="hashtag-label">6</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_7" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="7" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_7" class="hashtag-label">7</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_8" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="8" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_8" class="hashtag-label">8</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_9" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="9" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_9" class="hashtag-label">9</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_number_of_new_contacts_10" name="how_satisfied_have_you_been_with_the_number_of_new_contacts_" onChange={(e) => setRateSatisfyWithNewContact(e.target.value)} value="10" />
                                <label for="how_satisfied_have_you_been_with_the_number_of_new_contacts_10" class="hashtag-label">10</label>
                            </div>
                        </div>

                        <h4 class="no-margin">How satisfied have you been with the quality of new contacts?</h4>
                            <p class="info-text">0 = not satisfied; 10 = very satisfied</p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_0" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="0" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_0" class="hashtag-label">0</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_1" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="1" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_1" class="hashtag-label">1</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_2" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="2" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_2" class="hashtag-label">2</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_3" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="3" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_3" class="hashtag-label">3</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_4" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="4" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_4" class="hashtag-label">4</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_5" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="5" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_5" class="hashtag-label">5</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_6" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="6" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_6" class="hashtag-label">6</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_7" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="7" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_7" class="hashtag-label">7</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_8" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="8" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_8" class="hashtag-label">8</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_9" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="9" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_9" class="hashtag-label">9</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="how_satisfied_have_you_been_with_the_quality_of_new_contacts_10" name="how_satisfied_have_you_been_with_the_quality_of_new_contacts_" onChange={(e) => setRateSatisfyWithQualityNewContact(e.target.value)} value="10" />
                                <label for="how_satisfied_have_you_been_with_the_quality_of_new_contacts_10" class="hashtag-label">10</label>
                            </div>
                        </div>

    
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                            <h4 class="no-margin">What do you like about CCW?</h4>
                                {/* <label class="block-label regular" for="please_tell_us_the_main_reason_for_your_decision">What do you like about CCW?:</label> */}
                                <textarea name="what_do_you_like_about_ccw_" id="what_do_you_like_about_ccw_" onChange={(e) => setLikeAboutCcw(e.target.value)} class="required"></textarea>
                            </div>
                        </div>

                        <h5 class="">May we quote you with name and company?</h5>
                        {/* <p class="info-text">Kann man das etwas kleiner setzen / einrücken?</p> */}
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="may_we_quote_you_with_name_and_company_0" name="may_we_quote_you_with_name_and_company_" onChange={(e) => setQuoteNameCompany(e.target.value)} value="Yes" />
                                <label for="may_we_quote_you_with_name_and_company_0" class="hashtag-label">YES</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="may_we_quote_you_with_name_and_company_1" name="may_we_quote_you_with_name_and_company_" onChange={(e) => setQuoteNameCompany(e.target.value)} value="No" />
                                <label for="may_we_quote_you_with_name_and_company_1" class="hashtag-label">NO</label>
                            </div>
                        </div>
                        

                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <h4 class="no-margin">What can we do better?</h4>
                                <label class="block-label regular" for="what_can_we_do_better_"></label>
                                <textarea name="what_can_we_do_better_" id="what_can_we_do_better_" onChange={(e) => setWhatCanWeDoBetter(e.target.value)}></textarea>
                            </div>
                        </div>

                        <h4 class="no-margin">Has the digital platform added value for you?</h4>
                        <p class="info-text"></p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="has_the_digital_platform_added_value_for_you_0" name="has_the_digital_platform_added_value_for_you_" onChange={(e) => setAddedValueToYou(e.target.value)} value="Yes" />
                                <label for="has_the_digital_platform_added_value_for_you_0" class="hashtag-label">YES</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"50px", "minHeight":"30px;"}}>
                                <input type="radio" id="has_the_digital_platform_added_value_for_you_1" name="has_the_digital_platform_added_value_for_you_" onChange={(e) => setAddedValueToYou(e.target.value)} value="No" />
                                <label for="has_the_digital_platform_added_value_for_you_1" class="hashtag-label">NO</label>
                            </div>
                        </div>
                        
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <h4 class="no-margin">Please tell us the reason why:</h4>
                                <label class="block-label regular" for="please_tell_us_the_reason_why_"></label>
                                <textarea name="please_tell_us_the_reason_why_" id="please_tell_us_the_reason_why_" onChange={(e) => setReason(e.target.value)}></textarea>
                            </div>
                        </div>

                        <div class="button-box form-row flex-box">
                            <div class="field-item box-100 txt-right">
                                <button class="button call-to-action" name="submit" type="submit"><span class="button-label">Submit</span></button>
    
                            </div>
    
                        </div>
                    </div>
                </form>
                ) : 'Thank you for your feedback!'}
            </div>
            </section>
        </main>
        <Footer />
</div>
  )
}