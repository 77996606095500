import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import $ from "jquery";
// import { useUserback } from '@userback/react'
import { ChatContext } from "../Context/ChatContext";
import { doRequest } from "../utils/doRequest";
import { BeatLoader } from "react-spinners";

export default function Chat() {
  const { chatUserDetail, setChatUserDetail, messageObj, setMessageObj } =
    React.useContext(ChatContext);

  let [hidden, setHidden] = React.useState("hidden");
  let [loading, setLoading] = useState(0);
  // let [chatUserDetail, setChatUserDetail] = React.useState({});
  let [messageCount, setMessageCount] = React.useState(0);

  let [chatUsers, setChatUsers] = React.useState([]);
  // let [messageObj, setMessageObj] = React.useState({});

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
  let decryptedData = "";
  // const { open, hide } = useUserback();

  $(function () {
    $(document).on("keyup", ".search-notification", function () {
      if (this.value.length) {
        var that = this;
        $("#notification-results .notification-item")
          .hide()
          .filter(function () {
            return (
              $(this).html().toLowerCase().indexOf(that.value.toLowerCase()) !==
              -1
            );
          })
          .show();
        $("#notification-results").show();
      } else {
        $("#notification-results .notification-item").show();
      }
    });
  });

  useEffect(() => {
    // console.log("messageObj", messageObj);
    if (messageObj) {
      setHidden("");
      document.body.classList.add("chat-detail");
    } else {
      document.body.classList.remove("chat-detail");
    }
  }, [messageObj]);

  function openChatDetail(item) {
    setHidden(null);
    // console.log("document.body.classList", document.body.classList);
    let scroll = true;
    let message = {
      to: item.uid,
      from: item.touser,
      scroll,
      online: item.onlineStatus,
      username: `${item.first_name} ${item.last_name}`,
    };
    setMessageObj(message);
    setChatUserDetail(item);
  }

  function backToOverView() {
    setHidden("hidden");
    let message = null;
    setMessageObj(message);
    setChatUserDetail({});
  }

  function chatIframeLoad() {
    var chatFrame = $(".rocket-chat-iframe");
    chatFrame[0].contentWindow.postMessage(messageObj, "*");
    // console.log('iframe loaded.... ', messageObj);
  }

  function openMMProfile(chatUserDetail) {
    var openMMof = chatUserDetail.touser
      ? chatUserDetail.touser
      : chatUserDetail.uid;
    console.log(chatUserDetail);
    doRequest(
      "Server",
      "server.php",
      "GET",
      {
        type: "checkisMatchActive",
        uid: openMMof,
      },
      true,
      (err, data) => {
        /**
         * check if the user
         *
         * if(data.response && openMMof && chatUserDetail.onlineStatus == 1)
         */

        if (data.response && openMMof) {
          window.location.href = "/userinfo/" + openMMof;
        } else {
          alert(process.env.REACT_APP_CHAT_OPEN_MM_PROFILE_ERROR);
        }
      }
    );
  }

  var bytes = CryptoJS.AES.decrypt(
    localStorage.getItem("contextVar24").toString(),
    secretKey
  );

  if (bytes.toString(CryptoJS.enc.Utf8)) {
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //console.log('decryptedData : '+ decryptedData)
  }

  // const navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setLoading(1);
      document.body.classList.add("chat-active");
      // document.body.classList.remove('chat-detail');

      var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
      let resetChatStatus = function () {
        var currUser = $("#currUser").val();
        var myKeyVals = {
          resetChatStauts: 1,
          to: currUser,
        };

        $.ajax({
          type: "POST",
          url: chatUrl,
          data: myKeyVals,
          dataType: "text",
          dataType: "json",
          success: function (resultData) {},
        });
      };
      resetChatStatus();
    }

    let getMessagesFromChat = function () {
      var currUser = $("#currUser").val();
      var myKeyVals = {
        fetchMessages: 1,
        to: currUser,
      };

      if (
        !document.body.classList.contains("chat-detail") &&
        document.body.classList.contains("chat-active")
      ) {
        var saveData = $.ajax({
          type: "POST",
          url: chatUrl,
          data: myKeyVals,
          dataType: "text",
          dataType: "json",
          success: function (resultData) {
            doRequest(
              "Server",
              "server.php",
              "GET",
              {
                user: decryptedData,
                type: "chatUsers",
              },
              "",
              (err, data) => {
                setChatUsers(data.response);

                let totalCount = resultData.filter(function (value) {
                  return value.m_from == currUser && value.is_read == 0;
                }).length;

                let totalMsgCount = resultData.reduce(function (prev, current) {
                  return prev + +current.unread_count
                }, 0);

                let p = window.top;
                $(".matchmaking-counter", window.parent.document).html(
                  totalMsgCount
                );
                let currentRequests = parseInt(
                  $("#currentRequests", window.parent.document).val()
                );

                if (
                  totalMsgCount > currentRequests &&
                  $(".search-notification").val() == ""
                ) {
                  $("#currentRequests", window.parent.document).val(totalMsgCount);
                  setMessageCount(totalMsgCount);
                }

                $("#notification-results .notification-item").each(function () {
                  var to = $(this).attr("data-to");
                  var from = $(this).attr("data-from");

                  var length = resultData.filter(function (value) {
                    return (
                      value.m_from == to &&
                      value.m_to == from &&
                      value.is_read == 0
                    );
                  }).length;

                  $(this)
                    .find(".button-count")
                    .html(length > 0 ? length : "");
                  var allMessages = resultData.filter(function (value) {
                    //return value.m_from == to && value.is_read == 0
                    return (
                      (value.m_from == to && value.m_to == from) ||
                      (value.m_from == from && value.m_to == to)
                    );
                  });

                  var time = 0;
                  var message = "";
                  var msgTime = "";
                  if (allMessages.length) {
                    var message = allMessages.sort(function (a, b) {
                      return parseFloat(b["tstamp"]) - parseFloat(a["tstamp"]);
                    })[0]["message"];

                    var time = allMessages.sort(function (a, b) {
                      return parseFloat(b["tstamp"]) - parseFloat(a["tstamp"]);
                    })[0]["tstamp"];

                    var msgTime = allMessages.sort(function (a, b) {
                      return parseFloat(b["tstamp"]) - parseFloat(a["tstamp"]);
                    })[0]["time"];

                    var unreadCount = allMessages.sort(function (a, b) {
                      return parseFloat(b["tstamp"]) - parseFloat(a["tstamp"]);
                    })[0]["unread_count"];
                  }

                  let messageStr = message;
                  if (message.length > 30) {
                    messageStr = message.substring(0, 30);
                  }

                  $(this).find(".button-box").html(messageStr);
                  $(this).find(".time-box").html(msgTime);
                  $(this).attr("data-time", time);
                  $(this)
                    .find(".button-count")
                    .html(unreadCount > 0 ? unreadCount : "");
                });

                var sortedHtml = $(
                  "#notification-results .notification-item"
                ).sort((a, b) => $(b).data("time") - $(a).data("time"));
                $("#notification-results").html(sortedHtml);
                setLoading(0);
              }
            );
          },
        });
      }
    };

    getMessagesFromChat();

    const timer = setInterval(getMessagesFromChat, 10000);

    return () => {
      isSubscribed = false;
      document.body.classList.remove("chat-active");
      document.body.classList.remove("chat-detail");
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div
        id="overlay-iframe"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "0",
        }}
      >
        <input type="hidden" id="currUser" value={decryptedData} />
        {hidden != "" ? (
          <div id="notification-list">
            <div className="notification-chat-item">
              <input
                className="search-notification"
                type="text"
                placeholder="Search Chat User"
              />
            </div>
            {loading ? <BeatLoader color="white" /> : ""}
            <div id="notification-results">
              <>
                {chatUsers &&
                  chatUsers.map((item, val) => {
                    // console.log(chatUsers, "chat users");
                    let image =
                      process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                      "typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg";
                    if (item.image) {
                      image =
                        process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                        item.image;
                    }

                    return (
                      <div
                        key={item.uid}
                        className="notification-item"
                        onClick={() => openChatDetail(item)}
                        data-from={item.touser}
                        data-to={item.uid}
                        data-online={item.onlineStatus}
                      >
                        <div className="notification-info flex-box">
                          <div className="flex-item user-image">
                            <div className="bg-image">
                              <figure className="image-box">
                                <img
                                  srcSet={`${image} 360w,
                                                    ${image} 560w`}
                                  sizes="(max-width: 560px) 100vw, 560px"
                                  src={image}
                                />
                              </figure>
                            </div>
                          </div>
                          <div className="info-box">
                            <strong className="user-name cl-white">
                              {item.first_name} {item.last_name}
                            </strong>
                            <div className="button-box cl-white"></div>
                            <div
                              className={`button-count online-${item.onlineStatus}`}
                            ></div>
                            <span className="time-box"></span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            </div>
          </div>
        ) : (
          ""
        )}
        {hidden != "hidden" ? (
          <div id="notification-detial">
            <div className="flex-box">
              <div className="flex-item flex-box center-center">
                <button id="notification-back" onClick={() => backToOverView()}>
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                  Overview
                </button>
              </div>
              <div className="notification-item">
                <div className="notification-info flex-box">
                  <div className="user-image">
                    <div className="bg-image">
                      <figure className="image-box">
                        {chatUserDetail.image ? (
                          <img
                            srcSet={`${
                              process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                              chatUserDetail.image
                            } 360w,
                                                    ${
                                                      process.env
                                                        .REACT_APP_SERVER_ENDPOINT_TYPO3 +
                                                      chatUserDetail.image
                                                    } 560w`}
                            sizes="(max-width: 560px) 100vw, 560px"
                            src={
                              process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                              chatUserDetail.image
                            }
                          />
                        ) : (
                          <img
                            srcSet={`${
                              process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                              "typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg"
                            } 360w,
                                                ${
                                                  process.env
                                                    .REACT_APP_SERVER_ENDPOINT_TYPO3 +
                                                  "typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg"
                                                } 560w`}
                            sizes="(max-width: 560px) 100vw, 560px"
                            src={
                              process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
                              "typo3conf/ext/tsbasetemplate/Resources/Public/images/icon-user.svg"
                            }
                          />
                        )}
                      </figure>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      openMMProfile(chatUserDetail);
                    }}
                    style={{ "border-bottom": "1px solid #ddd" }}
                    className="user-name cl-white"
                  >
                    {chatUserDetail.first_name} {chatUserDetail.last_name}
                  </div>
                </div>
              </div>
            </div>
            <div className="rocket-chat-iframe-box">
              <iframe
                className="rocket-chat-iframe"
                id="rocket-chat-iframe"
                src={`${process.env.REACT_APP_CHAT_ENDPOINT}`}
                frameBorder="0"
                onLoad={() => chatIframeLoad()}
              ></iframe>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
