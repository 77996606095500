import { useContext, useEffect } from "react";
import overlayContext from "../Context/overlayContext";
import StreamVideo from "../Live/StreamVideo";
import {
  decryptedUserId,
  getCookie,
  positionBottom,
  positionLeft,
  positionRight,
  positionTop,
  shrinkEnlarge,
} from "../utils";
import StickyVideo from "./StickyVideo";
import CloseSVG from "./Ui/CloseSVG";
import EnlargeSVG from "./Ui/EnlargeSVG";
import ShrinkSVG from "./Ui/ShrinkSVG";

import VideoOverlay from "./Overlays/VideoOverlay";
import PodcastOverlay from "./Overlays/PodcastOverlay";
import WhitepaperOverlay from "./Overlays/WhitepaperOverlay";
import ConsetOverlay from "./Overlays/ConsetOverlay";
import Firstmyagenda from "./Overlays/Firstmyagenda";
import FirstMatchmaker from "./Overlays/FirstMatchmaker";
import FirstSearchForTopics from "./Overlays/FirstSearchForTopics";
import FirstCompanySpaces from "./Overlays/FirstCompanySpaces";
import FirstQuickPlatformTour from "./Overlays/FirstQuickPlatformTour";
import Chat from "../Chat/Chat";
import { ChatContext } from "../Context/ChatContext";
import ExhibitorOverlay from "./Overlays/ExhibitorOverlay";
import ArchiveVideo from "../Live/ArchiveVideo";
import { useState } from "react";

export default function Overlays() {
  const { showOverlay, setShowoverlay, overlayData, setOverlayData } =
    useContext(overlayContext);
  const { chatUserDetail, setChatUserDetail, messageObj, setMessageObj } =
    useContext(ChatContext);

  function closeOverlay() {
    setShowoverlay("");
    setMessageObj(null);
    setChatUserDetail({});
  }

  function ShowLiveStreamOverlay() {
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
      return () => {
        console.log('tme cleare');
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      }
    }, [])

    if (overlayData.showConsent && getCookie("consent") != 1) {
      return <ConsetOverlay showOverlayOF="livestream" />;
    }

    return (
      <div
        className="overlay live-stream scroll-overlay active"
        id="livestream-overlay"
      >
        <div className="item-box">
          <button
            className="shrink-enlarge se-overlay"
            onClick={shrinkEnlarge}
            id="shrink-enlarge"
          >
            <ShrinkSVG />
            <EnlargeSVG />
          </button>
          <button
            onClick={closeOverlay}
            className="close close-overlay"
            id="close-stream-box"
          >
            <CloseSVG />
          </button>
          <button
            onClick={() => positionTop()}
            className="position-button position-top"
            id="position-top"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionBottom()}
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionLeft()}
            className="position-button position-left"
            id="position-left"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionRight()}
            className="position-button position-right"
            id="position-right"
          >
            <span></span>
          </button>
          <div className="overlay-container pd-8">
            <StreamVideo timeoutId={timeoutId} setTimeoutId={setTimeoutId} />
          </div>
        </div>
      </div>
    );
  }

  function ShowArchiveLiveStreamOverlay() {
    if (overlayData.showConsent && getCookie("consent") != 1) {
      return <ConsetOverlay showOverlayOF="livestream" />;
    }

    return (
      <div
        className="overlay live-stream scroll-overlay active"
        id="livestream-overlay"
      >
        <div className="item-box">
          <button
            className="shrink-enlarge se-overlay"
            onClick={shrinkEnlarge}
            id="shrink-enlarge"
          >
            <ShrinkSVG />
            <EnlargeSVG />
          </button>
          <button
            onClick={closeOverlay}
            className="close close-overlay"
            id="close-stream-box"
          >
            <CloseSVG />
          </button>
          <button
            onClick={() => positionTop()}
            className="position-button position-top"
            id="position-top"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionBottom()}
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionLeft()}
            className="position-button position-left"
            id="position-left"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionRight()}
            className="position-button position-right"
            id="position-right"
          >
            <span></span>
          </button>
          <div className="overlay-container pd-8">
            <ArchiveVideo />
          </div>
        </div>
      </div>
    );
  }

  function ShowTutorialsOverlay() {
    return (
      <div
        className="overlay active tutorial scroll-overlay"
        id="tutorial-overlay"
      >
        <div className="item-box">
          <button
            onClick={() => closeOverlay()}
            className="close close-overlay"
          >
            <CloseSVG />
          </button>
          <div class={`overlay-container video-content cl-white pd-t12 pd-b5`}>
            <div
              class="overlay-title flex-box center-left"
              onClick={() => {
                if (document.getElementById("video-mp4")) {
                  document.getElementById("video-mp4").scrollIntoView();
                }
              }}
            >
              <strong>Video</strong>
            </div>
            <div class="col-box col-2">
              <div class="col-item">
                <h3>Video CCWdigital</h3>
                <p>A lot to expect. Please watch the video here</p>
              </div>
              <div class="col-item"></div>
            </div>
            <div>
              <StickyVideo />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ShowHallPlanOverlay() {
    return (
      <div
        className="overlay hallplan scroll-overlay active"
        id="hallplan-overlay"
      >
        <div className="item-box">
          <button
            className="close close-overlay"
            onClick={() => closeOverlay()}
          >
            <CloseSVG />
          </button>
          <div className="overlay-container whitepaper-content cl-white pd-t12 pd-b5 floorplan-download">
            <div className="overlay-title flexbox center-left"></div>
            <div className="col-box col-2">
              <div className="col-item">
                <h3>Floorplan CCW</h3>
                <p>Download the floorplan of the CCW</p>
              </div>
              <div className="col-item flex-box bottom-center">
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS}/fileadmin/user_upload/Floor_Plan_CCW_2024.pdf`}
                  className="button call-to-action"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ShowFeedbackOverlay() {
    return (
      <div className="overlay feedback active" id="feedback-overlay">
        <div className="item-box">
          <button
            onClick={() => closeOverlay()}
            className="close close-overlay"
          >
            <CloseSVG />
          </button>
          <div className="overlay-container">
            <div className="iframe-box">
              <iframe
                id="overlay-iframe"
                src="/feedback"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ShowFeedbackForConfrence() {
    return (
      <div
        className="overlay hallplan scroll-overlay active"
        id="hallplan-overlay"
      >
        <div className="item-box">
          <button
            className="close close-overlay"
            onClick={() => closeOverlay()}
          >
            <CloseSVG />
          </button>
          <div className="overlay-container cl-white pd-t12 pd-b5">
            <ExhibitorOverlay />
          </div>
        </div>
      </div>
    );
  }

  function ChatOverlay() {
    useEffect(() => {
      document.body.classList.add("chat-active");
      return () => {
        document.body.classList.remove("chat-detail");
        document.body.classList.remove("chat-active");
      };
    }, []);

    return (
      <div
        className={`overlay chat-notifications active`}
        id="matchmaking-overlay"
      >
        <div className="item-box">
          <button
            className="close close-overlay"
            onClick={() => closeOverlay()}
          >
            <CloseSVG />
          </button>
          <div className="overlay-container">
            <div className="iframe-box">
              <Chat />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function EasyAppointmentOverlay() {
    return (
      <div class="overlay easyappointment active" id="easyappointment-overlay">
        <div class="item-box">
          <button onClick={closeOverlay} class="close close-overlay">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill-rule="evenodd"
              stroke-miterlimit="10"
              clip-rule="evenodd"
              viewBox="0 0 41 41"
            >
              <g transform="translate(4.022 3.96)">
                <clipPath id="a">
                  <path
                    d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"
                    clip-rule="nonzero"
                  ></path>
                </clipPath>
                <g clip-path="url(#a)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    fill="none"
                    stroke="#fff"
                    transform="rotate(135 15.28 15.738)"
                  ></circle>
                  <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
          <div class="overlay-container pd-8">
            <div class="iframe-box">
              <iframe
                id="overlay-iframe"
                src={overlayData.src}
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ShowEasterEggOverlay() {
    return (
      <div
        className="overlay easter-egg live-stream scroll-overlay active"
        id="easter-egg-overlay"
      >
        <div className="item-box">
          <button
            onClick={closeOverlay}
            className="close close-overlay"
            id="close-stream-box"
          >
            <CloseSVG />
          </button>
          <button
            onClick={() => positionTop()}
            className="position-button position-top"
            id="position-top"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionBottom()}
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionLeft()}
            className="position-button position-left"
            id="position-left"
          >
            <span></span>
          </button>
          <button
            onClick={() => positionRight()}
            className="position-button position-right"
            id="position-right"
          >
            <span></span>
          </button>
          <div className="overlay-container pd-8">
            <div id="stream-video-container">
              <div className="col-box col-2 live-stream-box">
                <div className={`col-item box-70 fullwidth`}>
                  <div className="iframe-box" style={{ height: "100%" }}>
                    <iframe
                      id="vimeo-player"
                      src="https://qualitycluster-ccwkibot-ccwkibot-oonl92.streamlit.app/?embedded=true"
                      width="600"
                      height="100%"
                      allow="autoplay; fullscreen"
                      allowFullScreen=""
                      style={{ height: "100%" }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ShowAskQuestionOverlay() {
    useEffect(() => {
      document.body.classList.add("chat-active");
      return () => {
        document.body.classList.remove("chat-detail");
        document.body.classList.remove("chat-active");
      };
    }, []);

    return (
      <div className="overlay chat-notifications scroll-overlay active">
        <div className="item-box">
          <button
            className="close close-overlay"
            onClick={() => closeOverlay()}
          >
            <CloseSVG />
          </button>
          <div className="overlay-container">
            <div className="overlay-title flexbox center-left"></div>
            <div className="col-box">
              <div className="col-item flex-box bottom-center">
              <iframe
                src={`https://slido-ccw.codersunlimited.com/www/setcookie.php?session=${decryptedUserId()}`}
                height={0}
                width={0}
              />
                <iframe
                  src="https://app.sli.do/event/xeq5exPAguFYp5EM8hknbB/live/questions"
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  style={{ minHeight: "calc(100vh - 85px)" }}
                  title="Slido"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showOverlay && showOverlay == "feedback" ? (
        <ShowFeedbackOverlay />
      ) : null}
      {showOverlay && showOverlay == "hallplan" ? (
        <ShowHallPlanOverlay />
      ) : null}
      {showOverlay && showOverlay == "tutorial" ? (
        <ShowTutorialsOverlay />
      ) : null}

      {showOverlay && showOverlay == "FirstMyAgenda" ? <Firstmyagenda /> : null}
      {showOverlay && showOverlay == "FirstMatchmaker" ? (
        <FirstMatchmaker />
      ) : null}
      {showOverlay && showOverlay == "FirstSearchForTopics" ? (
        <FirstSearchForTopics />
      ) : null}
      {showOverlay && showOverlay == "FirstCompanySpaces" ? (
        <FirstCompanySpaces />
      ) : null}
      {showOverlay && showOverlay == "FirstQuickPlatformTour" ? (
        <FirstQuickPlatformTour />
      ) : null}

      {showOverlay && showOverlay == "livestream" ? (
        <ShowLiveStreamOverlay />
      ) : null}
      {showOverlay && showOverlay == "archiveLivestream" ? (
        <ShowArchiveLiveStreamOverlay />
      ) : null}

      {showOverlay && showOverlay == "csVideo" ? <VideoOverlay /> : null}
      {showOverlay && showOverlay == "csPodcast" ? <PodcastOverlay /> : null}
      {showOverlay && showOverlay == "csWhitepaper" ? (
        <WhitepaperOverlay />
      ) : null}
      {showOverlay && showOverlay == "chat" ? <ChatOverlay /> : null}
      {showOverlay && showOverlay == "Consent" ? <ConsetOverlay /> : null}
      {showOverlay && showOverlay == "articleConset" ? (
        <ConsetOverlay showOverlayOF={"article"} />
      ) : null}
      {showOverlay && showOverlay == "EA" ? <EasyAppointmentOverlay /> : null}

      {showOverlay && showOverlay == "ai-feedback" ? (
        <ShowFeedbackForConfrence />
      ) : null}

      {showOverlay && showOverlay == "easterEgg" ? (
        <ShowEasterEggOverlay />
      ) : null}

      {showOverlay && showOverlay == "askQuestion" ? (
        <ShowAskQuestionOverlay />
      ) : null}

      {/* <!-- Overlay Archived Video --> */}
      <div
        className="overlay flex-box center-center stream-archive"
        id="stream-archive"
      >
        <div className="item-box tab-box">
          <button
            className="shrink-enlarge se-overlay"
            onClick={shrinkEnlarge}
            id="shrink-enlarge"
          >
            <span className="icon-box shrink">
              <i className="icon"></i>
            </span>
            <span className="icon-box enlarge">
              <i className="icon"></i>
            </span>
          </button>
          <button className="close close-overlay" id="close-stream-box">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>

          <button
            onClick={() => positionTop()}
            className="position-button position-top"
            id="position-top"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            onClick={() => positionBottom()}
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            onClick={() => positionLeft()}
            className="position-button position-left"
            id="position-left"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            onClick={() => positionRight()}
            className="position-button position-right"
            id="position-right"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>

          <div className="overlay-container pd-8">
            <div className="iframe-box">
              {/* <iframe id="overlay-iframe" scrolling="no" src="/stream-video" frameBorder="0" width="100%" height="100%"></iframe> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Overlay User Leads --> */}
      <div
        className="overlay flex-box center-center user-leads"
        id="user-leads"
      >
        <div className="item-box tab-box">
          <button className="close close-overlay" id="close-stream-box">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-top" id="position-top">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-left" id="position-left">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-right"
            id="position-right"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>

          <div className="overlay-container cl-white pd-t12 pd-b5">
            <div className="overlay-title flex-box center-left">
              <strong>User Statistics</strong>
            </div>
            <div className="col-box col-3">
              <div className="col-item user-stats-container"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
