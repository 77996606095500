import React, {createContext, useEffect, useState} from "react";
import { decryptedUserId } from '../../utils';
import { doRequest } from "../../utils/doRequest";


export const MyAgendaContext = createContext({
    userAgenda: [],
    setUserAgenda: () => {},
    toggleMyagenda: () => {}
});

const MyAgendaProvider = props => {
    const [userAgenda, setUserAgenda ] = React.useState([]);
    
    useEffect(() => {
        if (decryptedUserId()) {
            doRequest('React',"lib/storage/users/" + decryptedUserId() + "/myagenda.json", 'GET',{
                v: (new Date()).getTime()
            },'',(err,data) => { 
                if (data.eventID) {
                setUserAgenda(data.eventID);
                }
            });
        }
    }, [decryptedUserId()]);

    return (
        <MyAgendaContext.Provider value={{userAgenda: userAgenda, setUserAgenda: setUserAgenda}}>
            {props.children}
        </MyAgendaContext.Provider>
    )
}

export default MyAgendaProvider;