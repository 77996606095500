import React, {createContext, useEffect, useState} from "react";
import { cryptedUserId, decryptedUserId, isLogin, logout, savedProfile } from "../utils";
import { doRequest } from "../utils/doRequest";

export const AuthContext = createContext({
    isAuth: false,
    hasLiveAccess: false,
    login: () => {},
    logout: () => {},
    profile: {},
    setProfile: () => {}
});



const AuthContextProvider = props => {
    const [isAuthenticated, setIsAuthenticated] = useState(isLogin);
    const [profile, setProfile] = useState({});

    useEffect(() => {
        if(decryptedUserId()) {
            doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/profile.json', 'GET', {v: (new Date()).getTime()}, true, (err, data) => {
                setProfile(data);
            });
            doRequest(
                'Server',
                'server.php',
                'GET',
                {
                    type: 'updateActiveTime',
                    user: cryptedUserId(),
                }, false, (err,data) => {
                console.log()
            });
            setInterval(function () {
                doRequest(
                    'Server',
                    'server.php',
                    'GET',
                    {
                    type: 'updateActiveTime',
                    user: cryptedUserId(),
                    }, false, (err,data) => {
                    console.log()
                });
            }, 120000);
        }
    }, [isAuthenticated])

    const loginHandler = () => {
        setIsAuthenticated(true);
    }

    const logoutHandler = () => {
        setIsAuthenticated(false);
        logout()
    }

    return (
        <AuthContext.Provider value={{login: loginHandler, isAuth: isAuthenticated, logout: logoutHandler, profile: profile, setProfile: setProfile}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;