import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { cryptString, decryptedUserId } from "../utils";
import { doRequest } from "../utils/doRequest";

export default function Rate({ initialValue, stream, speaker, message }) {
  const [rating, setRating] = useState(initialValue);
  const authContext = useContext(AuthContext);
  const { profile, setProfile } = authContext;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile.ratings && profile.ratings.length > 0) {
      profile.ratings
        .filter(
          (rating) => rating.speaker === speaker && rating.stream === stream
        )
        .map((rating) => setRating(rating.rating));
    }
  }, []);

  const handleRatingChange = (newRating) => {
    setLoading(true);
    setRating(newRating);
    let params = {
      user: decryptedUserId(),
      rating: newRating,
      speaker: speaker,
      stream: stream,
      message: message,
    };

    let data = JSON.stringify(params);

    doRequest(
      "Server",
      "server.php",
      "GET",
      {
        type: "ratings",
        param: encodeURIComponent(data),
      },
      true,
      (err, data) => {
        console.log(data);
        setRating(newRating);

        doRequest(
          "React",
          "/lib/storage/users/" + decryptedUserId() + "/profile.json",
          "GET",
          {v: (new Date()).getTime()},
          true,
          (err, data) => {
            setProfile(data);
            setLoading(false);
          }
        );
      }
    );

    // if (stream && profile?.ratings?.length > 0) {
    //   let newProfile = profile;
    //   newProfile.ratings = [
    //     ...profile.ratings,
    //     { rating: newRating, speaker: speaker, stream: stream },
    //   ];
    //   setProfile(newProfile);
    // }
  };

  if (loading) {
    return "loading...";
  }

  return (
    <div className="rate-section">
      <div>
        {[1, 2, 3, 4, 5].map((value) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            version="1.1"
            viewBox="0 0 47.94 47.94"
            xmlSpace="preserve"
            onClick={() => handleRatingChange(value)}
          >
            <path
              fill={value <= rating ? "gold" : "gray"}
              d="M26.285 2.486l5.407 10.956a2.58 2.58 0 001.944 1.412l12.091 1.757c2.118.308 2.963 2.91 1.431 4.403l-8.749 8.528a2.582 2.582 0 00-.742 2.285l2.065 12.042c.362 2.109-1.852 3.717-3.746 2.722l-10.814-5.685a2.585 2.585 0 00-2.403 0l-10.814 5.685c-1.894.996-4.108-.613-3.746-2.722l2.065-12.042a2.582 2.582 0 00-.742-2.285L.783 21.014c-1.532-1.494-.687-4.096 1.431-4.403l12.091-1.757a2.58 2.58 0 001.944-1.412l5.407-10.956c.946-1.919 3.682-1.919 4.629 0z"
            ></path>
          </svg>
        ))}
      </div>
        <span>
        Rate the speaker!
        </span>
    </div>
  );
}
