import React, {createContext, useEffect, useState} from "react";


export const LoadingContext = createContext({
    loading: false,
    setLoading: () => {},
    isError: () => {},
    errorArr: []
});



const LoadingContextProvider = props => {
    const [loading, setLoading] = useState(false);

    return (
        <LoadingContext.Provider value={{loading: loading, setLoading: setLoading}}>
            {props.children}
        </LoadingContext.Provider>
    )
}

export default LoadingContextProvider;