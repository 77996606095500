import axios from "axios";
import { Navigate } from "react-router-dom";
import { AES, enc, pad } from "crypto-js";
import { doRequest } from "./doRequest";
import { data } from "jquery";

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

const TOKEN_KEY = "contextVar24";

export const login = (props) => {
  localStorage.setItem(TOKEN_KEY, "TestLogin");
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  document.cookie =
    "fe_typo_user=;expires=Thu, 01 Jan 1971 00:00:01 GMT;path=/;";
  window.location.href =
    process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 +
    "/user-login?logintype=logout";
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
    return true;
  }
  return false;
};

export const decryptedUserId = () => {
  if (localStorage.getItem("contextVar24")) {
    var bytes = AES.decrypt(
      localStorage.getItem("contextVar24").toString(),
      secretKey
    );

    if (bytes.toString(enc.Utf8)) {
      return JSON.parse(bytes.toString(enc.Utf8));
    }
  } else {
    return "";
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const cryptedUserId = () => {
  if (localStorage.getItem("contextVar24")) {
    var bytes = AES.decrypt(
      localStorage.getItem("contextVar24").toString(),
      secretKey
    );

    let key = enc.Hex.parse("0123456789abcdef0123456789abcdef");
    let iv = enc.Hex.parse("abcdef9876543210abcdef9876543210");

    if (bytes.toString(enc.Utf8)) {
      let msg = JSON.parse(bytes.toString(enc.Utf8)).toString();
      return AES.encrypt(msg, key, {
        iv: iv,
        padding: pad.ZeroPadding,
      }).toString();
    }
  } else {
    return "";
  }
};

export const cryptString = (msg) => {
  let key = enc.Hex.parse("0123456789abcdef0123456789abcdef");
  let iv = enc.Hex.parse("abcdef9876543210abcdef9876543210");
  return AES.encrypt(JSON.stringify(msg), key, {
    iv: iv,
    padding: pad.ZeroPadding,
  }).toString();
};

/**
 * LiveStream functions:
 */

export const shrinkEnlarge = () => {
  // if($('body').hasClass('shrink-overlay')) {
  //     $('body').removeClass('shrink-overlay');
  //     $('iframe').each(function() {
  //         $(this).removeAttr('scrolling')
  //     })
  // } else {
  //     $('body').addClass('shrink-overlay');
  //     $('iframe').each(function() {
  //         $(this).attr('scrolling', 'no')
  //     })
  // }

  if (document.body.classList.contains("shrink-overlay")) {
    document.body.classList.remove("shrink-overlay");
  } else {
    document.body.classList.add("shrink-overlay");
  }
};

export const positionTop = () => {
  document.body.classList.add("shrink-top");
  document.body.classList.remove("shrink-bottom");
};

export const positionBottom = () => {
  document.body.classList.add("shrink-bottom");
  document.body.classList.remove("shrink-top");
};

export const positionLeft = () => {
  document.body.classList.add("shrink-left");
  document.body.classList.remove("shrink-right");
};

export const positionRight = () => {
  document.body.classList.add("shrink-right");
  document.body.classList.remove("shrink-left");
};

export const StreamName = ({ stream }) => {
  let stageName = "";

  if (stream.stage == 1 || stream.stage == 2) {
    stageName = process.env.REACT_APP_PROGRAM_TAB_1;
  }
  if (stream.stage == 3 || stream.stage == 4) {
    stageName = process.env.REACT_APP_PROGRAM_TAB_2;
  }
  if (stream.stage == 5 || stream.stage == 6) {
    stageName = process.env.REACT_APP_PROGRAM_TAB_3;
  }

  return stageName;
};

export const contentStyle = (style) => {
  let contentStyleClass = "narrow-item";
  // if(style == 1) {
  //   contentStyleClass = 'narrow-item';
  // }
  // if(style == 2) {
  //   contentStyleClass = 'wide-item';
  // }
  // if(style == 3) {
  //   contentStyleClass = 'big-item';
  // }

  return contentStyleClass;
};

export function jumpToSection(section) {
  let getIdOf = section.split("#")[1];
  setTimeout(() => {
    let sectionElem = document.getElementById(getIdOf);

    if (sectionElem) {
      let bodyRect = document.body.getBoundingClientRect();
      let pos = sectionElem.getBoundingClientRect();
      let offset =
        pos.top -
        bodyRect.top -
        4 * parseFloat(getComputedStyle(document.body).fontSize);
      window.scrollTo(0, offset);
      // sectionElem.scrollIntoView();
    }
  }, 800);
}
