import Hero from "./Hero";

import StickyLinks from "./StickyLinks";
import AgendaSection from './AgendaSection';
import ProgramSection from "./ProgramSection";

import { MyAgendaProvider } from './Context/myAgendaContext';
import { ProgramProvider } from "./Context/programContext";

import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId, jumpToSection, setCookie } from '../utils';

import BeatLoader from "react-spinners/BeatLoader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import overlayContext from "../Context/overlayContext";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const Newsroom = lazy(() => import('./Newsroom.js'));
const FirstView = lazy(() => import('./FirstView'));
const NextLiveStreamUpOnStage = lazy(() => import('./NextLiveStreamUpOnStage'));
const Exhibitor = lazy(() => import('./Exhibitor'));
const FindYourBisnessPartner = lazy(() => import('./FindYourBuisnessPartner'));
const SponsorsSection = lazy(() => import('./SponsorsSection'));
const MediaPartner = lazy(() => import('./MediaPartner'));


export default function LivePage ({setIsLoggedInUser, isLoggedInUser}) {
    const { setShowoverlay } = useContext(overlayContext);
    const { profile } = useContext(AuthContext);

    const [programs, setProgram ] = useState([]);
    const [userInfo, setUserInfo] = useState();   
    const [loading, setLoading] = useState(true);
    const [showloader, setShowloader] = useState(false);

    let location = useLocation();

    useEffect(() => {
        window?.HubSpotConversations?.widget?.refresh();
        document.body.classList.add('live');
        if(window.location.hash) {
            jumpToSection(window.location.hash)
            window.history.replaceState(null, null, ' ');
        }
        return () => {
            document.body.classList.remove('live');
        }
    }, [])

    useEffect(() => {
        window?.HubSpotConversations?.widget?.refresh();
        if(window.location.hash) {
            jumpToSection(window.location.hash)
            window.history.replaceState(null, null, ' ');
        }
    },[location])

    

    useEffect(()=> {
        let isSubscribed = true;

        if(profile) {
            setLoading(false);
            setUserInfo(profile);
            if (profile && profile.firstview == 0) {
                doRequest(
                    'Server',
                    'server.php',
                    'GET', 
                    {
                        localId: process.env.REACT_APP_LOCAL_ID,
                        user: decryptedUserId(),
                        type: 'livePageVist'
                    },
                    false,
                    (err,data) => {}
                );
            }
            if (profile && profile.third_party_consent != 1) {
                doRequest( 'Server', 'server.php', 'GET', {
                        type: "consent",
                        getConsent : 1,
                        uid: decryptedUserId()
                    },
                    false,
                    (err,data) => {
                        if(parseInt(data.responseArr.third_party_consent) == 0) {
                            setShowoverlay('Consent');
                        }
                        setCookie('consent', data.responseArr.third_party_consent, 365);
                    }
                );
            } else if (profile && profile.third_party_consent == 1) {
                setCookie('consent', profile.third_party_consent, 365);
            }
        }
        return () => (isSubscribed = false)
    },[profile])
    
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    if(loading) {
        return (<BeatLoader style={style} />);
    }

    return (
        <div className="base-box fixed" id="page-top">
            <StickyLinks />
            <Header setIsLoggedInUser={setIsLoggedInUser} isLoggedInUser={isLoggedInUser} />
                <div className="base-item" >
                    <Hero />
                    <main className="main-content">
                        {profile && !profile?.firstview && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <FirstView />
                            </Suspense>
                        )}
                        <Suspense fallback={<div>Loading...</div>}>
                            <NextLiveStreamUpOnStage />
                        </Suspense>
                        <ProgramProvider value={{ programs, setProgram }}>
                            <AgendaSection setShowloader={setShowloader} />
                            <ProgramSection />
                        </ProgramProvider>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Newsroom />
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Exhibitor key={'exhibitor'} />
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <FindYourBisnessPartner />
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <SponsorsSection />
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                            <MediaPartner />
                        </Suspense>
                        
                    </main>
            </div>
            <Footer type={'live'} />
            {showloader ? <div className="small-loader"><BeatLoader /></div> : ''}
        </div>
    );
}