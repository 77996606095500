import React, {createContext, useEffect, useState} from "react";
import { doRequest } from "../utils/doRequest";
import { isLogin } from "../utils";

export const TopicsContext = createContext({
    topics: {},
    setTopics: () => {}
});

const TopicsContextProvider = props => {
    const [topics, setTopics] = useState({});
    
    // useEffect(() => {       
    //     let isSubscribed = true;
    //     if(topics.length !== 0 && isSubscribed && isLogin()) {
    //         doRequest(
    //             'React',
    //             'lib/storage/content/Topics.json',
    //             'GET', 
    //             {},
    //             false,
    //             (err,data) => {
    //                 setTopics(data);
    //             }
    //         );
    //     }
        
    //     return () => (isSubscribed = false)

    // }, [isLogin()]);

    return (
        <TopicsContext.Provider value={{topics: topics, setTopics: setTopics}}>
            {props.children}
        </TopicsContext.Provider>
    )
}

export default TopicsContextProvider;