import { useContext } from "react";
import overlayContext from "../../Context/overlayContext";
import CloseSVG from "../Ui/CloseSVG";

export default function FirstMatchmaker() {
    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);
    function closeOverlay() {
        setShowoverlay('');
    }
  return (
    <div className="overlay active tutorial scroll-overlay" id="tutorial-overlay">
        <div className="item-box">
                <button onClick={() => closeOverlay()} className="close close-overlay">
                    <CloseSVG />
                </button>
                <div className="overlay-container pd-8">
                    <div>
                    <div className="overlay-iframe">
                        <div className="video-box">
                            <div className="inner">
                            <div className="ce-textpic ce-center ce-above">
                                <div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
                                <div className="ce-outer">
                                    <div className="ce-inner">
                                    <div className="ce-row">
                                        <div className="ce-column">
                                        <figure className="video">
                                            <div className="video-embed">
                                            <video
                                                width="1500"
                                                controls
                                                no-cookie
                                                className="video-embed-item"
                                            >
                                                <source
                                                src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3_ASSETS + "/fileadmin/user_upload/CCW_2023_Tutorials/9_CCW_visitors-how-to-use-the-matchmaker.mp4"}
                                                type="video/mp4"
                                                />
                                            </video>
                                            </div>
                                        </figure>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}
